import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sdf-notification-admin',
  templateUrl: './sdf-notification-admin.component.html',
  styleUrls: ['./sdf-notification-admin.component.css']
})
export class SdfNotificationAdminComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
