import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sdf-menu',
  templateUrl: './sdf-menu.component.html',
  styleUrls: ['./sdf-menu.component.css']
})
export class SdfMenuComponent implements OnInit {

  constructor() { }

  hide_opt:boolean = false;
  toggle_choice:any={
    'type':{
      'Vertical':'vertical',
      'Horisontal':'horisontal'
    }
  }

  toggle_o_selected:any={
    'type':'horisontal',
  }

  ngOnInit(): void {
  }

  i_repeat:number=0;hide_component:boolean=true;
  toggle_selected(val,v){


    this.i_repeat++;
    if(this.i_repeat>1){
      this.toggle_o_selected[v]=this.toggle_choice[v][val];
      this.hide_component=false;
      setTimeout(()=>{
        this.hide_component=true;
      },2)
    }
    
  }

  preview(ty:string){
    //console.log(ty)
    if(ty=='vertical')
      window.open('#/sdf-vertical-menu','_blank');
    else
      window.open('#/sdf-horisontal-menu','_blank');
  }


}
