import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-test-page',
  templateUrl: './test-page.component.html',
  styleUrls: ['./test-page.component.css']
})
export class TestPageComponent implements OnInit {

  paGroupDropDownList:any = [];
  dfv:any = [];
  dfv1:any = ['Ac','ac'];
  states:string = 'Unselected';
  constructor() { }

  ngOnInit(): void {

    setTimeout(()=>{
      this.dfv = [ { 'value': 'HFB 7', 'code': '1003' }, { 'value': 'HFB 4', 'code': '1002'}, { 'value': 'HFB 8', 'code': '1002'}];
    },500)
    setTimeout(()=>{
      this.states = 'Selected';
    },5000)
    setTimeout(()=>{
      this.states = 'Unselected';
    },8000)

  }

}
