import { ChangeDetectorRef, Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sdf-new-banner',
  templateUrl: './sdf-new-banner.component.html',
  styleUrls: ['./sdf-new-banner.component.css']
})
export class SdfNewBannerComponent implements OnInit {

  constructor(private cdref: ChangeDetectorRef) { }
  hide_opt:boolean = false;
  toggle_choice:any={
    'type':{
      'Overlap':'overlap',
      'Push':'push'
    },
    'title':{
      'Yes':'yes',
      'No':'no'
    },
    'icon':{
      'Yes':'yes',
      'No':'no'
    },
    'dismissible':{
      'Auto':'auto',
      'Manual':'manual'
    },
    'action_button':{
      'None':'none',
      '1':'one',
      '2':'two'
    }
  }

  toggle_o_selected:any={
    'type':'overlap'
  }

  /*
    all variables come here
  */
  title:string='Title';
  banner_msg:string='Title Labore deserunt nisi commodo velit labore labore occaecat commodo consectetur lorem ipsum. 0123456789012345678901234567890123456789012345678901234567890123456789 0123456789012345678901234567890123456789012345678901234567890123456789';
  icon_type:string='icon';//image/icon
  icon:string='fa fa-home';
  dismissible:boolean=true;//true,false
  show_banner_popup:boolean=false;
  bannerState:boolean=false;

  button_data:any={
    'none':[],
    'one':[
      {
        'label':'Close',
        'type':'secondary'
      }
    ],
    'two':[
      {
        'label':'Close',
        'type':'secondary'
      },
      {
        'label':'Submit',
        'type':'primary'
      }
    ]
  }

  ngOnInit(): void {
  }

  i_repeat:number=0;hide_component:boolean=true;
  toggle_selected(val,v){
    //this.i_repeat++;
    //if(this.i_repeat>4){
      this.toggle_o_selected[v]=this.toggle_choice[v][val];
      this.hide_component=false;
      setTimeout(()=>{
        this.hide_component=true;
      },2)
    //}
    
  }

  show_banner(){
    this.show_banner_popup=true;
  }

  buttonClicked(val:string){
    if(val=='Close'){
      this.bannerState = true;
    }
    setTimeout(()=>{
      this.bannerState = false;
      this.show_banner_popup =false;
    },100)
  }

  bannerClosed(){
    this.show_banner_popup=false;
  }

  clicked(){
    console.log('button clicked')
  }
  ngAfterContentChecked() {

    this.cdref.detectChanges();

  }

}
