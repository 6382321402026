import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(private http: HttpClient) { }

  checkNotification(data,produrl:boolean=false,type:string='widget'){
    let url = produrl?environment.prodbaseUrl:environment.baseUrl;
    return this.http.get(url+'/sdf/api/release-content/v1/release-content/GetNotificationHighlight?userId='+(data.userId)+'&appCode='+(data.appCode)+'&type='+type);
  }
  getPopupNotification (data,produrl:boolean=false){
    let url = produrl?environment.prodbaseUrl:environment.baseUrl;
    return this.http.get(url+'/sdf/api/release-content/v1/release-content/GetPopupNotification?userId='+(data.userId)+'&appCode='+(data.appCode));
  }
  closePopupNotification (data,produrl:boolean=false){
    let url = produrl?environment.prodbaseUrl:environment.baseUrl;
    return this.http.post(url+'/sdf/api/release-content/v1/release-content/ClosePopup?appCode='+(data.appCode)+'&userId='+(data.userId),data);
  }

  updateNotification(data,produrl:boolean=false,type:string='widget'):Observable<any>{
    let url = produrl?environment.prodbaseUrl:environment.baseUrl;
    return this.http.put(url+'/sdf/api/release-content/v1/release-content/UpdateNotification?userId='+(data.userId)+'&appCode='+(data.appCode)+'&type='+type,data);
  }

  get_request(url:string):Observable<any>{
    return this.http.get(url,{observe:'body',responseType:'text'});
  }
}
