import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sdf-typography',
  templateUrl: './sdf-typography.component.html',
  styleUrls: ['./sdf-typography.component.css']
})
export class SdfTypographyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
