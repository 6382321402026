// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  component_link_get:'https://sdftestapi.azurewebsites.net/sdf/api/release-content/v1/component/GetComponentLinks',
  component_link_update:'https://sdftestapi.azurewebsites.net/sdf/api/release-content/v1/component/UploadComponentLink',
  component_link_del:'https://sdftestapi.azurewebsites.net/sdf/api/release-content/v1/component/link_id',
  compenent_section_get:'https://sdftestapi.azurewebsites.net/sdf/api/release-content/v1/component/GetComponentSections',
  component_section_update:'https://sdftestapi.azurewebsites.net/sdf/api/release-content/v1/component/UploadComponentSection',
  component_section_del:'https://sdftestapi.azurewebsites.net/sdf/api/release-content/v1/component/section_id',
  component_media:'https://sdftestapi.azurewebsites.net/sdf/api/release-content/v1/component/UploadComponentMedia',
  apiUrl:'https://sdftestapi.azurewebsites.net/sdf/api/release-content/v1/component',
  blob_token:'https://sdftestapi.azurewebsites.net/sdf/api/release-content/v1/blobstorage/OAuthToken',
  sas_token:'https://sdftestapi.azurewebsites.net/sdf/api/release-content/v1/blobstorage/GetSasToken',
  nonprod_widget:'https://notificationwidget.azurewebsites.net',
  prod_widget:'https://notificationwidgetprod.azurewebsites.net',
  baseUrl:'https://sdftestapi.azurewebsites.net',
  prodbaseUrl:'https://notificationadminapi.azurewebsites.net',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
