import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-sdf-textarea-sample',
  templateUrl: './sdf-textarea-sample.component.html',
  styleUrls: ['./sdf-textarea-sample.component.css']
})
export class SdfTextareaSampleComponent implements OnInit {
  sampletext:string="sampletext";
  emptytext:string ="";
 

  constructor(private formBuilder: FormBuilder) {}
 
  ngOnInit() {
    
  }

  onChange(event) {
    console.log(event);
  }

  onKeypress(event) {
    console.log(event);
  }

  onFocus(event) {
    console.log(event);
  }

  onBlur(event) {
    console.log(event);
  }
}
