import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sdf-multi-hierarchy-dropdown-sample',
  templateUrl: './sdf-multi-hierarchy-dropdown-sample.component.html',
  styleUrls: ['./sdf-multi-hierarchy-dropdown-sample.component.scss']
})
export class SdfMultiHierarchyDropdownSampleComponent implements OnInit {

  data:any=[
    {"text":"demo"},
    {
      "text":"Living Room Furniture",
      "child":[
        {
          "text":"Sofas Neq",
          "child":
            [
              {"text":"Sofas Neq1"},
              {"text":"Sofa type"}
            ]
        },
        {
          "text":"Sectional New",
          "child":[
            {
              "text":"Sectional New1"
            }
          ]  
        },
        {
          "text":"Sleeper Sofa new"
        },
        {
          "text":"Accent Chairs new"
        },
        {
          "text":"half charir"
        }
      ]
    },
    {
      "text":"Kitchen Furniture",
      "child":[
        {"text":"Kitchen Cabinets new"},
        {"text":"Kitchen islands new"},
        {"text":"Pot Rachs new"},
        {"text":"Bar Stools And Chairs new"},
        {"text":"Kitchen Carts new"}
      ],
    }, 
    {
      "text":"Dining Room Furniture",
      "child":[
        {"text":"Dining Table"},{"text":"Dining Chair"},{"text":"Tool"}
      ],
    },  
    {
      "text":"Bedroom Furniture",
      "child":[
        {"text":"Beds new"},{"text":"Headboards new"},{"text":"Dressers new"}
      ],
    } 
  ]
  data3:any=[
    {"text":"demo","thumbnail_image":"https://th.bing.com/th/id/R.5d9d40e49c215fb20ad9ba0d82510f4c?rik=7qODJHk8gV27ww&pid=ImgRaw&r=0",},
    {
      "text":"Living Room Furniture","thumbnail":"fa fa-thumbs-up","color":"blue",
      "child":[
        {
          "text":"Sofas Neq","thumbnail":"fa fa-thumbs-up","color":"green",
          "child":
            [
              {"text":"Sofas Neq1","thumbnail":"fa fa-thumbs-up","color":"green"},
              {"text":"Sofa type","thumbnail":"fa fa-thumbs-up",}
            ]
        },
        {
          "text":"Sectional New","thumbnail":"fa fa-thumbs-up",
          "child":[
            {
              "text":"Sectional New1","thumbnail_image":"https://th.bing.com/th/id/R.5d9d40e49c215fb20ad9ba0d82510f4c?rik=7qODJHk8gV27ww&pid=ImgRaw&r=0",
            }
          ]  
        },
        {
          "text":"Sleeper Sofa new","thumbnail_image":"https://th.bing.com/th/id/R.5d9d40e49c215fb20ad9ba0d82510f4c?rik=7qODJHk8gV27ww&pid=ImgRaw&r=0",
        },
        {
          "text":"Accent Chairs new","thumbnail_image":"https://th.bing.com/th/id/R.5d9d40e49c215fb20ad9ba0d82510f4c?rik=7qODJHk8gV27ww&pid=ImgRaw&r=0",
        },
        {
          "text":"half charir","thumbnail_image":"https://th.bing.com/th/id/R.5d9d40e49c215fb20ad9ba0d82510f4c?rik=7qODJHk8gV27ww&pid=ImgRaw&r=0",
        }
      ]
    },
    {
      "text":"Kitchen Furniture","thumbnail_image":"https://th.bing.com/th/id/R.5d9d40e49c215fb20ad9ba0d82510f4c?rik=7qODJHk8gV27ww&pid=ImgRaw&r=0",
      "child":[
        {"text":"Kitchen Cabinets new","thumbnail_image":"https://th.bing.com/th/id/R.5d9d40e49c215fb20ad9ba0d82510f4c?rik=7qODJHk8gV27ww&pid=ImgRaw&r=0",},
        {"text":"Kitchen islands new","thumbnail_image":"https://th.bing.com/th/id/R.5d9d40e49c215fb20ad9ba0d82510f4c?rik=7qODJHk8gV27ww&pid=ImgRaw&r=0",},
        {"text":"Pot Rachs new","thumbnail_image":"https://th.bing.com/th/id/R.5d9d40e49c215fb20ad9ba0d82510f4c?rik=7qODJHk8gV27ww&pid=ImgRaw&r=0",},
        {"text":"Bar Stools And Chairs new","thumbnail_image":"https://th.bing.com/th/id/R.5d9d40e49c215fb20ad9ba0d82510f4c?rik=7qODJHk8gV27ww&pid=ImgRaw&r=0",},
        {"text":"Kitchen Carts new","thumbnail_image":"https://th.bing.com/th/id/R.5d9d40e49c215fb20ad9ba0d82510f4c?rik=7qODJHk8gV27ww&pid=ImgRaw&r=0",}
      ],
    }, 
    {
      "text":"Dining Room Furniture","thumbnail":"fa fa-thumbs-up","color":"blue",
      "child":[
        {"text":"Dining Table","thumbnail":"fa fa-thumbs-up","color":"blue",},{"text":"Dining Chair","thumbnail":"fa fa-thumbs-up","color":"blue",},{"text":"Tool","thumbnail":"fa fa-thumbs-up","color":"blue",}
      ],
    },  
    {
      "text":"Bedroom Furniture","thumbnail":"fa fa-thumbs-up","color":"blue",
      "child":[
        {"text":"Beds new","thumbnail":"fa fa-thumbs-up","color":"blue",},{"text":"Headboards new","thumbnail":"fa fa-thumbs-up","color":"blue",},{"text":"Dressers new","thumbnail":"fa fa-thumbs-up","color":"blue",}
      ],
    } 
  ]
  data4:any=[
    {
      "text":"Asia",
      "child":[
        {
          "text":"China","thumbnail":"fa fa-thumbs-up","color":"green",
        },
        {
          "text":"India","thumbnail":"fa fa-thumbs-up", 
        },
        {
          "text":"Iran","thumbnail_image":"https://th.bing.com/th/id/R.5d9d40e49c215fb20ad9ba0d82510f4c?rik=7qODJHk8gV27ww&pid=ImgRaw&r=0",
        },
        {
          "text":"SriLanka","thumbnail_image":"https://th.bing.com/th/id/R.5d9d40e49c215fb20ad9ba0d82510f4c?rik=7qODJHk8gV27ww&pid=ImgRaw&r=0",
        },
        {
          "text":"Bhutan","thumbnail_image":"https://th.bing.com/th/id/R.5d9d40e49c215fb20ad9ba0d82510f4c?rik=7qODJHk8gV27ww&pid=ImgRaw&r=0",
        }
      ]
    },
    {
      "text":"Europe",
      "child":[
        {"text":"Spain","thumbnail_image":"https://th.bing.com/th/id/R.5d9d40e49c215fb20ad9ba0d82510f4c?rik=7qODJHk8gV27ww&pid=ImgRaw&r=0",},
        {"text":"Germany","thumbnail_image":"https://th.bing.com/th/id/R.5d9d40e49c215fb20ad9ba0d82510f4c?rik=7qODJHk8gV27ww&pid=ImgRaw&r=0",},
        {"text":"Italy","thumbnail_image":"https://th.bing.com/th/id/R.5d9d40e49c215fb20ad9ba0d82510f4c?rik=7qODJHk8gV27ww&pid=ImgRaw&r=0",},
        {"text":"Paris","thumbnail_image":"https://th.bing.com/th/id/R.5d9d40e49c215fb20ad9ba0d82510f4c?rik=7qODJHk8gV27ww&pid=ImgRaw&r=0",},
        {"text":"UK","thumbnail_image":"https://th.bing.com/th/id/R.5d9d40e49c215fb20ad9ba0d82510f4c?rik=7qODJHk8gV27ww&pid=ImgRaw&r=0",}
      ],
    }, 
    {
      "text":"North America",
      "child":[
        {"text":"New York","thumbnail":"fa fa-thumbs-up","color":"blue",},
        {"text":"Canada","thumbnail":"fa fa-thumbs-up","color":"blue",},
        {"text":"Buston","thumbnail":"fa fa-thumbs-up","color":"blue",}
      ],
    },  
    {
      "text":"South America",
      "child":[
        {"text":"Brazil","thumbnail":"fa fa-thumbs-up","color":"blue",},
        {"text":"Maxico","thumbnail":"fa fa-thumbs-up","color":"blue",},
        {"text":"Colombia","thumbnail":"fa fa-thumbs-up","color":"blue",}
      ],
    } 
  ]
  //loading_sign:boolean=false;// loading spinner will be shown if set to true
  data2:any=[];
  constructor() { }

  ngOnInit() {
    setTimeout(()=>{
      this.data2 = this.data3.slice();
    },10)
  }

  valueChanged(object:string){
    // object is selected object -- object.value, object.ref_value, object.extra_val
    console.log(object);
  }

  valueSelected(object:string){
    console.log(object);
  }

  valueCleared(){
    console.log('value cleared');
  }

}
