import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sdf-new-button-sample',
  templateUrl: './sdf-new-button-sample.component.html',
  styleUrls: ['./sdf-new-button-sample.component.scss']
})
export class SdfNewButtonSampleComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  clicked(){
    console.log('button clicked');
  }

}
