import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sdf-inline-alert-sample',
  templateUrl: './sdf-inline-alert-sample.component.html',
  styleUrls: ['./sdf-inline-alert-sample.component.css']
})
export class SdfInlineAlertSampleComponent implements OnInit {
  messagetype:string;
  message:string;
  alertmessagetype:string;
  alertmessage:string;
  alerttitle:string;
  sampletext:string='';
  constructor() { }

  ngOnInit() {
  }
  onKeypress(event){
    if(event.value.length>=1){
      this.messagetype='information';
      this.message = 'Password Need minimum 8 characters'
      if( event.value.length >= 2 &&  event.value.length <= 6){
        this.messagetype='warning';
        this.message = 'Your Password is weak,try greater than 6 length'
      }
      if( event.value.length >= 7){
        this.messagetype='confirmation';
        this.message = 'Password is Good'
      }
    }else{
      this.messagetype='error';
      this.message = 'Please enter the password'
    }
    
  }
  onBlur(event){
    if(typeof(event.value) == 'undefined' || event.value == ''){
      this.messagetype='error';
      this.message = 'Please enter the password'
    }
  }
  onAlertKeypress(event){
    if(event.value.length>=1){
      this.alertmessagetype='information';
      this.alerttitle ='Information';
      this.alertmessage = 'Password Need minimum 8 characters'
      if( event.value.length >= 2 &&  event.value.length <= 6){
        this.alertmessagetype='warning';
        this.alerttitle ='Warning';
        this.alertmessage = 'Your Password is weak,try greater than 6 length'
      }
      if( event.value.length >= 7){
        this.alertmessagetype='confirmation';
        this.alerttitle ='Success'
        this.alertmessage = 'Password is Good'
      }
    }else{
      this.alertmessagetype='error';
      this.alerttitle ='Error'
      this.alertmessage = 'Please enter the password'
    }
    
  }
  onAlertBlur(event){
     if(typeof(event.value) == 'undefined'|| event.value == ''){
      this.alertmessagetype='error';
      this.alerttitle ='Error'
      this.alertmessage = 'Please enter the password'
    }
  }
}
