import { Component, OnInit } from '@angular/core';
import {ODataSearchService} from '../../../core/services/o-data-search.service'

@Component({
  selector: 'app-sdf-multi-dropdown-sample',
  templateUrl: './sdf-multi-dropdown-sample.component.html',
  styleUrls: ['./sdf-multi-dropdown-sample.component.scss']
})
export class SdfMultiDropdownSampleComponent implements OnInit {

  // data to be passed as json in singe select auto complete
  data:any=[];
  // [
  //   {value:"AfghanistanAlbania"},
  //   {value:"Algeria"},
  //   {value:"Andorra"},
  //   {value:" Angola"},
  //   {value:"Anguilla"},
  //   {value:"Antigua & Barbuda"},
  //   {value:"Argentina"},
  //   {value:"Armenia"},
  //   {value:"Australia"},
  //   {value:"Austria"},
  //   {value:"Bermuda"},
  //   {value:"Bhutan"},
  //   {value:"Bolivia"},
  //   {value:"Bosnia & Herzegovina"},
  //   {value:"Botswana"},
  //   {value:"Brazil"},
  //   {value:"Brunei Darussalam"},
  //   {value:"Bulgaria"},
  //   {value:"Burkina Faso"},
  //   {value:"Canada"},
  //   {value:"Cape Verde"},
  //   {value:"Cayman Islands"},
  //   {value:"Central African Republic"},
  //   {value:"Chad"},
  //   {value:"Chile"},
  //   {value:"China"},
  //   {value:"China - Hong Kong / Macau"},
  //   {value:"Colombia"},
  //   {value:"Comorosa"},
  //   {value:"Eritrea"},
  //   {value:"Estonia"},
  //   {value:"Finland"},
  //   {value:"France"},
  //   {value:"French Guiana"},
  //   {value:"Georgia"},
  //   {value:"Germany"},
  //   {value:"Ghana"},
  //   {value:"Greece"},
  //   {value:"Grenada"},
  //   {value:"Guatemala"},
  //   {value:"Guinea"},
  //   {value:"Guinea-Bissau"},
  //   {value:"Guyana"},
  //   {value:"Haiti"},
  //   {value:"Honduras"},
  //   {value:"Hungary"},
  //   {value:"Iceland"},
  //   {value:"India"},
  //   {value:"India"},
  //   {value:"Indonesia"},
  //   {value:"Iran"},
  //   {value:"Jamaica"},
  //   {value:"Japan"},
  //   {value:"Jordan"},
  //   {value:"Kazakhstan"},
  //   {value:"Kuwait"},
  //   {value:"Kyrgyz Republic (Kyrgyzstan)"},
  //   {value:"Lebanon"},
  //   {value:"Lesotho"},
  //   {value:"Malawi"},
  //   {value:"Malaysia"},
  //   {value:"Nepal"},
  //   {value:"Netherlands"},
  //   {value:"New Zealand"},
  //   {value:"Oman"},
  //   {value:"Pacific Islands"},
  //   {value:"Pakistan"},
  //   {value:"Panama"},
  //   {value:"Portugal"},
  //   {value:"Puerto Rico"},
  //   {value:"Qatar"},
  //   {value:"Reunion"},
  //   {value:"Romania"},
  //   {value:"Russian Federation"},
  //   {value:"Rwanda"},
  //   {value:"Saint Kitts and Nevis"},
  //   {value:"Saint Lucia"},
  //   {value:"Saint Vincent and the Grenadines"},
  //   {value:"Singapore"},
  //   {value:"Slovak Republic (Slovakia)"},
  //   {value:"Slovenia"},
  //   {value:"Solomon Islands"},
  //   {value:"Spain"},
  //   {value:"Sri Lanka"},
  //   {value:"Sweden"},
  //   {value:"Switzerland"},
  //   {value:"Syria"},
  //   {value:"Turkey"},
  //   {value:"Uruguay"},
  //   {value:"Uzbekistan"},
  //   {value:"Venezuela"},
  //   {value:"Vietnam"},
  //   {value:"Virgin Islands (UK)"},
  //   {value:"Virgin Islands (US)"},
  //   {value:"Yemen"},
  //   {value:"Zambia"},
  //   {value:"Zimbabwe"},
  //   {value:'demo',ref_value:'india',extra_val:'anything'}

  // ]

  loading_sign:boolean=false;// loading spinner will be shown if set to true

  constructor(private ODataSearchService: ODataSearchService) { }

  ngOnInit() {
    this.ODataSearchService.getCountires().subscribe(res =>{
      this.data= res.map(item => {
        return {"value":item}
      })
  })
}

  valueChanged(object){
    // object is selected object -- object.value, object.ref_value, object.extra_val
    console.log(object);
  }

  valueCleared(){
    console.log('value cleared');
  }

  valueSelected(object){
    console.log(object);
  }

}
