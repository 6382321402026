import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SdfButtonSampleComponent } from './sdf-samples/sdf-button-sample/sdf-button-sample.component';
import { SdfTextboxSampleComponent } from './sdf-samples/sdf-textbox-sample/sdf-textbox-sample.component';
import { SdfTextareaSampleComponent } from './sdf-samples/sdf-textarea-sample/sdf-textarea-sample.component';
import { SdfDatepickerSampleComponent } from './sdf-samples/sdf-datepicker-sample/sdf-datepicker-sample.component';
import { SdfSelectSampleComponent } from './sdf-samples/sdf-select-sample/sdf-select-sample.component';
import { SdfGridSampleComponent } from './sdf-samples/sdf-grid-sample/sdf-grid-sample.component';
import { SdfTabsSampleComponent } from './sdf-samples/sdf-tabs-sample/sdf-tabs-sample.component';
import { SdfMenuSampleComponent } from './sdf-samples/sdf-menu-sample/sdf-menu-sample.component';
import { LoginComponent, HomeComponent } from './sdf-samples/modules';
import { SdfTooltipSampleComponent } from './sdf-samples/sdf-tooltip-sample/sdf-tooltip-sample.component';
import { SdfBannerSampleComponent } from './sdf-samples/sdf-banner-sample/sdf-banner-sample.component';
import { SdfPillsSampleComponent } from './sdf-samples/sdf-pills-sample/sdf-pills-sample.component';
import { SdfAccordionSampleComponent } from './sdf-samples/sdf-accordion/sdf-accordion-sample.component';
import { SdfSwitchSampleComponent } from './sdf-samples/sdf-switch-sample/sdf-switch-sample.component';
import { SdfToggleSampleComponent } from './sdf-samples/sdf-toggle-sample/sdf-toggle-sample.component';
import { SdfAutocompleteSampleComponent } from './sdf-samples/sdf-autocomplete-sample/sdf-autocomplete-sample.component';
import { SdfDropdownSampleComponent } from './sdf-samples/sdf-dropdown-sample/sdf-dropdown-sample.component';
import { SdfCheckboxSampleComponent } from './sdf-samples/sdf-checkbox-sample/sdf-checkbox-sample.component';
import { SdfRadioSampleComponent } from './sdf-samples/sdf-radio-sample/sdf-radio-sample.component';
import { SdfInlineMessageSampleComponent } from './sdf-samples/sdf-inline-message-sample/sdf-inline-message-sample.component';
import { AuthGuard } from 'src/core/guards/auth.guard';
import { SdfNewButtonSampleComponent } from './sdf-samples/sdf-new-button-sample/sdf-new-button-sample.component';
import { SdfSingleAutocompleteSampleComponent } from './sdf-samples/sdf-single-autocomplete-sample/sdf-single-autocomplete-sample.component';
import { SdfMultiAutocompleteSampleComponent } from './sdf-samples/sdf-multi-autocomplete-sample/sdf-multi-autocomplete-sample.component';
import { SdfNewPillsSampleComponent } from './sdf-samples/sdf-new-pills-sample/sdf-new-pills-sample.component';
import { SdfMultiDropdownSampleComponent } from './sdf-samples/sdf-multi-dropdown-sample/sdf-multi-dropdown-sample.component';
import { SdfSingleDropdownSampleComponent } from './sdf-samples/sdf-single-dropdown-sample/sdf-single-dropdown-sample.component';
import { SdfInlineAlertSampleComponent } from './sdf-samples/sdf-inline-alert-sample/sdf-inline-alert-sample.component';
import { SdfAlertModalSampleComponent } from './sdf-samples/sdf-alert-modal-sample/sdf-alert-modal-sample.component';
import { SdfIconButtonSampleComponent } from './sdf-samples/sdf-icon-button-sample/sdf-icon-button-sample.component';
import { SdfTableSortingSampleComponent } from './sdf-samples/sdf-table-sorting-sample/sdf-table-sorting-sample.component';
import { SdfTableCommonFilterSampleComponent } from './sdf-samples/sdf-table-common-filter-sample/sdf-table-common-filter-sample.component';
import { SdfTableFilterRowSampleComponent } from './sdf-samples/sdf-table-filter-row-sample/sdf-table-filter-row-sample.component';
import { SdfTableSingleSelectionSampleComponent } from './sdf-samples/sdf-table-single-selection-sample/sdf-table-single-selection-sample.component';
import { SdfTableMultipleSelectionSampleComponent } from './sdf-samples/sdf-table-multiple-selection-sample/sdf-table-multiple-selection-sample.component';
import { SdfFileUploadSampleComponent } from './sdf-samples/sdf-file-upload/sdf-file-upload-sample.component';
import { SdfSingleHierarchyAutocompleteSampleComponent } from './sdf-samples/sdf-single-hierarchy-autocomplete-sample/sdf-single-hierarchy-autocomplete-sample.component';
import { SdfSingleHierarchyDropdownSampleComponent } from './sdf-samples/sdf-single-hierarchy-dropdown-sample/sdf-single-hierarchy-dropdown-sample.component';
import { SdfMultiHierarchyAutocompleteSampleComponent } from './sdf-samples/sdf-multi-hierarchy-autocomplete-sample/sdf-multi-hierarchy-autocomplete-sample.component';
import { SdfMultiHierarchyDropdownSampleComponent } from './sdf-samples/sdf-multi-hierarchy-dropdown-sample/sdf-multi-hierarchy-dropdown-sample.component';
import { SdfNewToggleSampleComponent } from './sdf-samples/sdf-new-toggle-sample/sdf-new-toggle-sample.component';
import { SdfNewAutocompleteSampleComponent } from './sdf-samples/sdf-new-autocomplete-sample/sdf-new-autocomplete-sample.component';
import { SdfTableComponent } from './sdf-samples/sdf-table/sdf-table.component';
import { SdfNewDropdownComponent } from './sdf-samples/sdf-new-dropdown/sdf-new-dropdown.component';
import { SdfButtonComponent } from './sdf-samples/sdf-button/sdf-button.component';
import { SdfPillsComponent } from './sdf-samples/sdf-pills/sdf-pills.component';
import { SdfInlineMessageComponent } from './sdf-samples/sdf-inline-message/sdf-inline-message.component';
import { SdfBannerComponent } from './sdf-samples/sdf-banner/sdf-banner.component';
import { SdfToastComponent } from './sdf-samples/sdf-toast/sdf-toast.component';
import { SdfRadioComponent } from './sdf-samples/sdf-radio/sdf-radio.component';
import { SdfCheckboxComponent } from './sdf-samples/sdf-checkbox/sdf-checkbox.component';
import { SdfSwitchComponent } from './sdf-samples/sdf-switch/sdf-switch.component';
import { SdfToggleComponent } from './sdf-samples/sdf-toggle/sdf-toggle.component';
import { SdfTooltipComponent } from './sdf-samples/sdf-tooltip/sdf-tooltip.component';
import { SdfDatepickerComponent } from './sdf-samples/sdf-datepicker/sdf-datepicker.component';
import { SdfTextboxComponent } from './sdf-samples/sdf-textbox/sdf-textbox.component';
import { SdfSearchComponent } from './sdf-samples/sdf-search/sdf-search.component';
import { SdfVerticalMenuComponent } from './sdf-samples/sdf-vertical-menu/sdf-vertical-menu.component';
import { SdfHorizontalMenuComponent } from './sdf-samples/sdf-horizontal-menu/sdf-horizontal-menu.component';
import { SdfTabsComponent } from './sdf-samples/sdf-tabs/sdf-tabs.component';
import { SdfMenuComponent } from './sdf-samples/sdf-menu/sdf-menu.component';
import { SdfColourComponent } from './sdf-samples/sdf-colour/sdf-colour.component';
import { SdfIconographyComponent } from './sdf-samples/sdf-iconography/sdf-iconography.component';
import { SdfTypographyComponent } from './sdf-samples/sdf-typography/sdf-typography.component';
import { SdfCloudInfraComponent } from './sdf-samples/sdf-cloud-infra/sdf-cloud-infra.component';
import { SdfBackendComponent } from './sdf-samples/sdf-backend/sdf-backend.component';
import { SdfContactComponent } from './sdf-samples/sdf-contact/sdf-contact.component';
import { UiHomeComponent } from './sdf-samples/ui-home/ui-home.component';
import { SdfNotificationCenterComponent } from './sdf-samples/sdf-notification-center/sdf-notification-center.component';
import { SdfNotificationWidgetComponent } from './sdf-samples/sdf-notification-widget/sdf-notification-widget.component';
import { SdfNotificationAdminComponent } from './sdf-samples/sdf-notification-admin/sdf-notification-admin.component';
import { SdfNotificationSystemComponent } from './sdf-samples/sdf-notification-system/sdf-notification-system.component';
import { SdfNewBannerComponent } from './sdf-samples/sdf-new-banner/sdf-new-banner.component';
import { SkapaWcButtonComponent } from './skapa-wc-button/skapa-wc-button.component';
import { TestPageComponent } from './test-page/test-page.component';
const appoutes: Routes = [
  {
    path: '',
    component: LoginComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuard]
  },
  
  
  {
    path: 'users',
    redirectTo: '/home'
  },
  {
    path: 'sdf-button-old',
    component: SdfButtonSampleComponent
  },
  { 
    path: 'sdf-autocomplete-single',
    component: SdfSingleAutocompleteSampleComponent
  },
  {
    path: 'sdf-autocomplete-multiple',
    component: SdfMultiAutocompleteSampleComponent
  },
  {
    path: 'sdf-checkbox-old',
    component: SdfCheckboxSampleComponent
  },
  {
    path: 'sdf-radio-old',
    component: SdfRadioSampleComponent
  },
  {
    path: 'sdf-textbox-old',
    component: SdfTextboxSampleComponent
  },
  {
    path: 'sdf-textarea',
    component: SdfTextareaSampleComponent
  },
  {
    path: 'sdf-datepicker-old',
    component: SdfDatepickerSampleComponent
  },
  {
    path: 'sdf-select',
    component: SdfSelectSampleComponent
  },
  {
    path: 'sdf-new-pills',
    component: SdfNewPillsSampleComponent
  },
  {
    path: 'sdf-grid',
    component: SdfGridSampleComponent
  },
  {
    path: 'sdf-banner',
    component: SdfNewBannerComponent//SdfBannerComponent
  },
  {
    path: 'sdf-tabs-old',
    component: SdfTabsSampleComponent
  },
  {
    path: 'sdf-switch-old',
    component: SdfSwitchSampleComponent
  },
  {
    path: 'sdf-menu-old',
    component: SdfMenuSampleComponent
  },
  {
    path: 'sdf-tooltip',
    component: SdfTooltipComponent
  },
  {
    path: 'sdf-accordion',
    component: SdfAccordionSampleComponent
  },
  {
    path: 'sdf-inline-message',
    component: SdfInlineMessageComponent
  },
  {
    path: 'sdf-toggle-old',
    component: SdfToggleSampleComponent
  },
  {
    path: 'sdf-toast',
    component: SdfToastComponent
  },
  
  {
    path:'sdf-inline-message',
    component: SdfInlineMessageSampleComponent
  },
  {
    path:'sdf-new-button',
    component:SdfNewButtonSampleComponent
  },
  {
    path:'sdf-single-autocomplete',
    component:SdfSingleAutocompleteSampleComponent
  },
  {
    path:'sdf-multi-autocomplete',
    component:SdfMultiAutocompleteSampleComponent
  },
  {
    path:'sdf-new-pills',
    component:SdfNewPillsSampleComponent
  },
  {
    path:'sdf-single-dropdown',
    component:SdfSingleDropdownSampleComponent
  },
  {
    path:'sdf-multi-dropdown',
    component:SdfMultiDropdownSampleComponent
  },
  {
    path:'sdf-inline-alert',
    component:SdfInlineAlertSampleComponent
  },
  {
    path:'sdf-alert-modal',
    component:SdfAlertModalSampleComponent
  },
  {
    path:'sdf-icon-button',
    component:SdfIconButtonSampleComponent
  },
  {
    path:'sdf-table-sorting',
    component:SdfTableSortingSampleComponent
  },
  {
    path:'sdf-table-common-filter',
    component:SdfTableCommonFilterSampleComponent
  },
  {
    path:'sdf-table-filter-row',
    component:SdfTableFilterRowSampleComponent
  },
  {
    path:'sdf-table-single-select',
    component:SdfTableSingleSelectionSampleComponent
  },
  {
    path:'sdf-table-multiselect',
    component:SdfTableMultipleSelectionSampleComponent
  },
  {
    path:'sdf-file-upload',
    component:SdfFileUploadSampleComponent
  },
  {
    path:'sdf-single-hierarchy-autocomplete',
    component:SdfSingleHierarchyAutocompleteSampleComponent
  },
  {
    path:'sdf-single-hierarchy-dropdown',
    component:SdfSingleHierarchyDropdownSampleComponent
  },
  {
    path:'sdf-multi-hierarchy-autocomplete',
    component:SdfMultiHierarchyAutocompleteSampleComponent
  },
  {
    path:'sdf-multi-hierarchy-dropdown',
    component:SdfMultiHierarchyDropdownSampleComponent
  },
  {
    path:'sdf-new-toggle',
    component:SdfNewToggleSampleComponent
  },
  //new component starts below
  {
    path:'sdf-button',
    component:SdfButtonComponent,
    canActivate: [AuthGuard]
  },
  {
    path:'sdf-autocomplete',
    component:SdfNewAutocompleteSampleComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'sdf-dropdown',
    component: SdfNewDropdownComponent,
    canActivate: [AuthGuard]
  },
  {
    path:'sdf-pills',
    component:SdfPillsComponent,
    canActivate: [AuthGuard]
  },
  {
    path:'sdf-table',
    component:SdfTableComponent,
    canActivate: [AuthGuard]
  },
  {
    path:'sdf-radio',
    component:SdfRadioComponent,
    canActivate: [AuthGuard]
  },
  {
    path:'sdf-checkbox',
    component:SdfCheckboxComponent,
    canActivate: [AuthGuard]
  },
  {
    path:'sdf-switch',
    component:SdfSwitchComponent,
    canActivate: [AuthGuard]
  },
  {
    path:'sdf-toggle',
    component:SdfToggleComponent,
    canActivate: [AuthGuard]
  },
  {
    path:'sdf-datepicker',
    component:SdfDatepickerComponent,
    canActivate: [AuthGuard]
  },
  {
    path:'sdf-textbox',
    component:SdfTextboxComponent,
    canActivate: [AuthGuard]
  },
  {
    path:'sdf-search',
    component:SdfSearchComponent,
    canActivate: [AuthGuard]
  },
  {
    path:'sdf-vertical-menu',
    component:SdfVerticalMenuComponent,
    canActivate: [AuthGuard]
  },
  {
    path:'sdf-horisontal-menu', 
    component:SdfHorizontalMenuComponent
  },
  {
    path:'sdf-tabs',
    component:SdfTabsComponent,
    canActivate: [AuthGuard]
  },
  {
    path:'sdf-menu',
    component:SdfMenuComponent,
    canActivate: [AuthGuard]
  },
  {
    path:'sdf-colour',
    component:SdfColourComponent,
    canActivate: [AuthGuard]
  },
  {
    path:'sdf-iconography',
    component:SdfIconographyComponent,
    canActivate: [AuthGuard]
  },
  {
    path:'sdf-typography',
    component:SdfTypographyComponent,
    canActivate: [AuthGuard]
  },
  {
    path:'sdf-cloud',
    component:SdfCloudInfraComponent,
    canActivate: [AuthGuard]
  },
  {
    path:'sdf-backend',
    component:SdfBackendComponent,
    canActivate: [AuthGuard]
  },
  {
    path:'sdf-contact',
    component:SdfContactComponent,
    canActivate: [AuthGuard]
  },
  {
    path:'ui-home',
    component:UiHomeComponent,
    canActivate: [AuthGuard]
  },
  {
    path:'sdf-notification-center',
    component:SdfNotificationCenterComponent,
    canActivate: [AuthGuard]
  },
  {
    path:'sdf-notification-widget',
    component:SdfNotificationWidgetComponent,
    canActivate: [AuthGuard]
  },
  {
    path:'sdf-notification-admin',
    component:SdfNotificationAdminComponent,
    canActivate: [AuthGuard]
  },
  {
    path:'sdf-notification-system',
    component:SdfNotificationSystemComponent,
    canActivate: [AuthGuard]
  },
  {
    path:'sdf-new-banner',
    component:SdfNewBannerComponent,
    canActivate:[AuthGuard]
  },
  {
    path:'skapa-web-components',
    component:SkapaWcButtonComponent
  },
  {
    path:'test-page',
    component:TestPageComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(appoutes, { useHash: true })],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule { }
