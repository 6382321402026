import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sdf-colour',
  templateUrl: './sdf-colour.component.html',
  styleUrls: ['./sdf-colour.component.css']
})
export class SdfColourComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
