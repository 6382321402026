import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sdf-accordion-sample',
  templateUrl: './sdf-accordion-sample.component.html',
  styleUrls: ['./sdf-accordion-sample.component.scss']
})
export class SdfAccordionSampleComponent implements OnInit {
  data:any=[]
  constructor() { }

  ngOnInit() {
    this.data=[{
      title:"hi",
      item:"accordion 1",
      expand:false
    },
    {
      title:"hi",
      item:"accordion 2",
      expand:false
    },
    {
      title:"hi",
      item:"accordion 3",
      expand:false
    }
    ]
  }

}
