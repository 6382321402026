import { Component, Injectable, OnInit } from '@angular/core';
import {menu} from '../../component-structure'

@Component({
  selector: 'app-sdf-vertical-menu',
  templateUrl: './sdf-vertical-menu.component.html',
  styleUrls: ['./sdf-vertical-menu.component.css']
})
@Injectable({
  providedIn: 'root'
})
export class SdfVerticalMenuComponent implements OnInit {

  constructor() { }


  menu = [
    { 
      title: "Home",
      path: "home"
    },
    { 
      title: 'Menu 1',
      children :[
        {
          title: 'Submenu 1'
        },
        {
          title: 'Submenu 2'
        }
      ]
    },
    { 
      title: 'Menu 2',
      children :[
        {
          title: 'Submenu 1'
        },
        {
          title: 'Submenu 2'
        }
      ]
    },
    { 
      title: 'Menu 3',
      children :[
        {
          title: 'Submenu 1'
        },
        {
          title: 'Submenu 2'
        }
      ]
    },
  ]
  bool:boolean=false;

  footer = {
    userName: 'UserName',
    initials: 'UN',
    items:
      [{
        text: "Alerts",
        icon: "fa fa-bell"
      },
      {
        text: "Settings",
        icon: "fa fa-cog"
      },
      {
        text: "Logout",
        icon: "fa fa-sign-out-alt"
      }]
  };


  title = {
    name: "App-Name",
    visible: true,
    path: ''
  };

  ngOnInit(): void {
    
  }

  check_me(bool:boolean){
    console.log('dd');
    let le = document.getElementById('v_menu') as HTMLElement;
    this.page_left=le.style.left;
    if(bool){
      this.page_left = this.page_left=='140px'?'310px':'500px';
      le.style.left = this.page_left;
    }else{
      this.page_left = this.page_left=='500px'?'310px':'140px';
      le.style.left = this.page_left;
    }
    console.log(this.page_left)
  }

  menu_clickedd(opt:string){
    switch(opt){
      case 'Alerts':
        //this.notification_com.clickWidget();
      break;
      case 'Logout':
        //this.authenticationService.logout();
      break;
      
    }
    console.log(opt);
  }

  page_left:string='250px'
  drawer_status(st:boolean){
    let le = document.getElementById('v_menu') as HTMLElement;
    this.page_left = le.style.left;
    if(st){
      this.page_left = this.page_left=='70px'?'250px':'70px';//'140px'?'310px':'500px';
      le.style.left=this.page_left;
    }else{
      this.page_left = this.page_left=='250px'?'70px':'250px';//'500px'?'310px':'140px';
      le.style.left=this.page_left;
    }
  }
}
