import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sdf-controls',
  templateUrl: './sdf-controls.component.html',
  styleUrls: ['./sdf-controls.component.css']
})
export class SdfControlsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
