import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-sdf-textbox-sample',
  templateUrl: './sdf-textbox-sample.component.html',
  styleUrls: ['./sdf-textbox-sample.component.css']
})
export class SdfTextboxSampleComponent implements OnInit {
  sampletext:string="sampletext";
  emptytext:string='';
  public data;

  constructor(private formBuilder: FormBuilder) {}
  ngOnInit() {this.customerdata()}
  customerdata(){
    this.data=[
      {CustomerID:'ALFKI',CompanyName:'ABCD',ContactName:'Maria Anders'},
      {CustomerID:'ANATR',CompanyName:'IKEA',ContactName:'Ana Trujillo'},
      {CustomerID:'ANTON',CompanyName:'EFGH',ContactName:'Antonio Moreno'},
      {CustomerID:'CACTU',CompanyName:'XYZ',ContactName:'Comidas para Anders'},
      {CustomerID:'ALFKI',CompanyName:'IKEA',ContactName:'Maria Anders'},
      {CustomerID:'CACTU',CompanyName:'PQRS',ContactName:'Cactus Comidas para'},
      {CustomerID:'ALFKI',CompanyName:'IKEA',ContactName:'Maria Anders'},
      {CustomerID:'CACTU',CompanyName:'IKEA',ContactName:'Cactus Comidas para'},
    ]
  }
  onChange(event) {
    console.log(event);
  }

  onKeypress(event) {
    console.log(event);
  }

  onFocus(event) {
    console.log(event);
  }

  onBlur(event) {
    console.log(event);
  }
  search(query){
    let items= this.data.filter(e=>e.CompanyName.toLowerCase().indexOf(query.toLowerCase())>-1)
    this.data =items;
  }
  searchItem(event){
    this.customerdata()
    if(event.value){
      let items= this.data.filter(e=>{
        return (e.CompanyName.toLowerCase().indexOf(event.value.toLowerCase())>-1) || 
        (e.CustomerID.toLowerCase().indexOf(event.value.toLowerCase())>-1) ||
        (e.ContactName.toLowerCase().indexOf(event.value.toLowerCase())>-1)
      })
      this.data =items;
    }
    
  }
  close(){
    this.customerdata()
  }
  
}
