import { ChangeDetectorRef, Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sdf-button',
  templateUrl: './sdf-button.component.html',
  styleUrls: ['./sdf-button.component.css']
})
export class SdfButtonComponent implements OnInit {

  constructor(private cdref: ChangeDetectorRef) { }

  hide_opt:boolean = false;hide_code:boolean=true;
  toggle_choice:any={
    'button_type':{
      'Primary':'primary',
      'Emphasised':'emphasised',
      'Secondary':'secondary',
      'Tertiary':'tertiary'
    },
    'button_height':{
      '32px':'XS',
      '40px':'S',
      '48px':'M',
      '56px':'L'
    },
    'width':{
      'Fluid':'fluid',
      'Auto':'auto'
    },
    'state':{
      'Emphasised':'Emphasised',
      'Subtle':'Subtle'
    },
    'text_icon':{
      'Text':'text',
      'Icon':'icon',
      'Icon+Text':"icon_text"
    },
    'icon_position':{
      'Leading':'left',
      'Trailing':'right'
    }
  }

  toggle_o_selected:any={
    'button_type':'primary',
    'button_height':'XS',
    'width':'auto',
    'state':'Subtle',
    'icon_position':'left',
    'text_icon':'text'
  }

  ngOnInit(): void {
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  i_repeat:number=0;hide_component:boolean=true;
  toggle_selected(val,v){
    this.i_repeat++;
    
   
    if(this.i_repeat>5){
      this.toggle_o_selected[v]=this.toggle_choice[v][val];
      this.toggle_o_selected['state']=this.toggle_o_selected['button_type']!='primary'?'Subtle':this.toggle_o_selected['state'];
      console.log(this.toggle_o_selected);
      this.hide_component=false;
      setTimeout(()=>{
        this.hide_component=true;
      },2)
    }
    
  }

  clicked(){
    console.log('button clicked')
  }

  copy_code(eli:any){
    eli = 'rtyur';//this.toggle_o_selected['thumbnail']=='none'?'rtyu':'rtyur';
    let el = document.getElementById(eli) as HTMLElement;
    let da = el.innerHTML.replace(/&lt;/g,'<');
    da = da.replace(/&gt;/g,'>')
    navigator.clipboard.writeText(da);
    console.log(da)
  }

}
