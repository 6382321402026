import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sdf-tooltip-sample',
  templateUrl: './sdf-tooltip-sample.component.html',
  styleUrls: ['./sdf-tooltip-sample.component.scss']
})
export class SdfTooltipSampleComponent implements OnInit {

  constructor() { }
  public staticData =[
    {CompanyName:'ALFKI',ContactName:'Maria Anders', Button1:{icon:'fas fa-calendar',disabled:false},Button2:{icon:'fas fa-edit',disabled:false}},
    {CompanyName:'ANATR',ContactName:'Ana Trujillo',Button1:{icon:'fas fa-play',disabled:false},Button2:{icon:'fas fa-save',disabled:false}},
    {CompanyName:'ANTON',ContactName:'Antonio Moreno',Button1:{icon:'fas fa-eye',disabled:false},Button2:{icon:'fas fa-edit',disabled:false}},
    {CompanyName:'CACTU',ContactName:'Comidas para Anders', Button1:{icon:'fas fa-calendar',disabled:true},Button2:{icon:'fas fa-save',disabled:true}},
    {CompanyName:'LAXSS',ContactName:'Mary Sanders', Button1:{icon:'fas fa-play',disabled:true},Button2:{icon:'fas fa-edit',disabled:true}},
    {CompanyName:'MAFRS',ContactName:'Cactus Comidas para', Button1:{icon:'fas fa-eye',disabled:true},Button2:{icon:'fas fa-save',disabled:true}},
    {CompanyName:'QEETW',ContactName:'Tesla Anders', Button1:{icon:'fas fa-calendar',disabled:false},Button2:{icon:'fas fa-edit',disabled:false}},
    {CompanyName:'PLLOO',ContactName:'Comidas para Anders', Button1:{icon:'fas fa-calendar',disabled:false},Button2:{icon:'fas fa-save',disabled:false}},
    {CompanyName:'YUIO',ContactName:'Maria Anders', Button1:{icon:'fas fa-calendar',disabled:false},Button2:{icon:'fas fa-edit',disabled:false}},
    {CompanyName:'VBMH',ContactName:'Cactus Comidas para', Button1:{icon:'fas fa-calendar',disabled:true},Button2:{icon:'fas fa-play',disabled:true}},
    {CompanyName:'MAFRS',ContactName:'Cactus Comidas para', Button1:{icon:'fas fa-calendar',disabled:false},Button2:{icon:'fas fa-save',disabled:false}},
    {CompanyName:'QEETW',ContactName:'Tesla Anders', Button1:{icon:'fas fa-calendar',disabled:false},Button2:{icon:'fas fa-edit',disabled:false}},
    {CompanyName:'PLLOO',ContactName:'Comidas para Anders', Button1:{icon:'fas fa-play',disabled:false},Button2:{icon:'fas fa-calendar',disabled:false}},
    {CompanyName:'YUIO',ContactName:'Maria Anders', Button1:{icon:'fas fa-calendar',disabled:false},Button2:{icon:'fas fa-save',disabled:false}},
    {CompanyName:'VBMH',ContactName:'Cactus Comidas para', Button1:{icon:'fas fa-calendar',disabled:true},Button2:{icon:'fas fa-edit',disabled:true}},
    {CompanyName:'IORE',ContactName:'Maria Anders', Button1:{icon:'fas fa-calendar',disabled:false},Button2:{icon:'fas fa-edit',disabled:false}},
    {CompanyName:'BNB',ContactName:'Cactus Comidas para', Button1:{icon:'fas fa-calendar',disabled:false},Button2:{icon:'fas fa-save',disabled:false}},
  ]
  ngOnInit() {
  }

}
