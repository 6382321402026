import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { SdfBannerService } from '@sdf/sdf-ui';

@Component({
  selector: 'app-sdf-banner',
  templateUrl: './sdf-banner.component.html',
  styleUrls: ['./sdf-banner.component.css']
})
export class SdfBannerComponent implements OnInit {

  constructor(private sdfBannerService: SdfBannerService,private cdref: ChangeDetectorRef) { }
  hide_opt:boolean = false;
  toggle_choice:any={
    'type':{
      'Non-closable':'default',
      'Dismissable':'dismiss'
    }
  }

  toggle_o_selected:any={
    'type':'default'
  }
  ngOnInit(): void {
  }

  showDialog(data) {
    let bannerData = {
      "title": "SDF_UI",
      "navBar": "vertical",
      "description": "Labore deserunt nisi commodo velit labore labore occaecat commodo consectetur. Labore deserunt nisi commodo velit labore labore occaecat commodo consectetur.",
      "type": "",
      "action": {}
    } 
    bannerData.type = data;
    if(data == 'default'){
      bannerData.action = {
        "btnPrimary": "Confirm",
        "btnSecondary": "Cancel",
      }
    }
    else{
      bannerData.action = {
        "readMore": {
          "path": "https://www.google.com"
        }
      }
    }
    this.sdfBannerService.confirmThis(bannerData, function () {  
      console.log('Confirm');
    }, function () {  
      console.log('Cancel');
    })  
  }
  i_repeat:number=0;hide_component:boolean=true;
  toggle_selected(val,v){
    //this.i_repeat++;
    //if(this.i_repeat>4){
      this.toggle_o_selected[v]=this.toggle_choice[v][val];
      console.log(this.toggle_o_selected);
      this.hide_component=false;
      setTimeout(()=>{
        this.hide_component=true;
      },2)
    //}
    
  }

  clicked(){
    console.log('button clicked')
  }
  ngAfterContentChecked() {

    this.cdref.detectChanges();

  }
}
