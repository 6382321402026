import { Component, OnInit,ViewChild, ViewEncapsulation, ElementRef } from '@angular/core';
import { SdfBannerService } from '@sdf/sdf-ui';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
//import { AuthenticationService } from 'src/core/services/authentication.service';
import { menu } from './component-structure';
import {SdfVerticalMenuComponent} from './sdf-samples/sdf-vertical-menu/sdf-vertical-menu.component';
import { environment as env } from '../environments/environment';

import { AuthenticationService } from 'src/core/services/authentication.service';
import { HomeService } from './sdf-samples/modules/home/home.service';
import { MonitorApplicationService } from 'src/app/sdf-samples/monitor-app/monitor-application.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  @ViewChild('notification_com') notification_com;


  // title = 'PetStore';
  pathString;
  appName: string;
  appText: string;
  menuItems = []
  menuItemss = []
  router;
  userdata:any = null;
  
  public login_page:boolean=false;
  public userData;
  public showNotification = false;
  newNotification: boolean = false;
  url_hash:string='';

  call_once_only:boolean=false;hide_menu_opt:boolean=false;
  constructor(private mapp:MonitorApplicationService,private vertical_menu:SdfVerticalMenuComponent,private elRef: ElementRef,private homeService: HomeService,private _router: Router, private location: Location,private authenticationService: AuthenticationService) {
    this.appName = "Pet Store";
    this.appText = "Pet store using angular"
    this.router = _router.url;
    this.pathString = location.path();this.hide_menu_opt=false;
    //console.log(this.pathString);

    _router.events.subscribe((val:any)=>{
      
      if(window.location.hash=='#/' || window.location.hash=='#/login'){
        this.login_page=true;
        this.notification_com.showNotification=false;
      }else if(window.location.hash=='#/sdf-vertical-menu' || window.location.hash=='#/sdf-horisontal-menu'){
        this.hide_menu_opt=true;
      }else{
        this.login_page=false;
        //if(this.un=='')
          this.user_type();

        if(this.call_once_only==false){
          this.display_user_name();
        }
        if(window.location.hash!='#/login' || '#/'){
          setTimeout(()=>{
            this.show_notification();
          },500)
        }
      }
    })
    
    //this.menuItem=this.menuService.getMenu();
    this.menuItems = menu;

  }

  ngOnInit() {
    // this.userdata= this.authenticationService.userData
    // console.log("hi",this.userdata)
    let url:string = window.location.toString();
    //this.mapp.logPageView('user 1',url);

    this.authenticationService.getUserDetails().subscribe(value =>{
      this.userdata= value;
    })
    this.user_type(); 

    
  }

  ngAfterViewInit(){
    //console.log(this.authenticationService.userId,'oooo')
    
  }

  show_notification_once:boolean=false; 

  show_notification(){
    if(!this.login_page && !this.show_notification_once){
      this.show_notification_once=true;

    this.setWorkerConn();  
    let data = {
      'appCode': 'SDF',//'ToCompete',PIMO-DC And Warehouse
      'userId': JSON.parse(localStorage.getItem('user'))
    }
    
    this.homeService.checkNotification(data).subscribe(res => {
      let flag: any = res;
      this.newNotification = flag;
      if(res)
        this.elRef.nativeElement.getElementsByClassName("notifyicon")[0].parentNode.classList.add("notifyactive")
      else
        this.elRef.nativeElement.getElementsByClassName("notifyicon")[0].parentNode.classList.remove("notifyactive")

        //console.log("newNotificationnewNotification",this.newNotification)
    });

    // for guide widget
    this.homeService.checkNotification(data,false,'guide').subscribe(res => {
      let flag: any = res;
      this.newNotification = flag;
      if(res)
        this.elRef.nativeElement.getElementsByClassName("notifyicon_w")[0].parentNode.classList.add("notifyactive")
      else
        this.elRef.nativeElement.getElementsByClassName("notifyicon_w")[0].parentNode.classList.remove("notifyactive")

        //console.log("newNotificationnewNotification",this.newNotification)
    });

    // for prod type
    if(data.userId=='admin'){
      this.homeService.checkNotification(data,true).subscribe(res => {
        let flag: any = res;
        this.newNotification = flag;
        if(res)
          this.elRef.nativeElement.getElementsByClassName("notifyicon")[1].parentNode.classList.add("notifyactive")
        else
          this.elRef.nativeElement.getElementsByClassName("notifyicon")[1].parentNode.classList.remove("notifyactive")
          //console.log("newNotificationnewNotification",this.newNotification)
      });
    }
      //this.notification_com.clickWidget();
    }
  }

  drawer_status(st:boolean){
    this.vertical_menu.check_me(st);
    if(st){
      this.notification_com.left_to='270px';
    }else{
      this.notification_com.left_to='70px';
    }
  }

  menu_clickedd(opt:string){
    switch(opt){
      case 'Notifications':
        this.notification_com.clickWidget();
        //this.newNotification=false
        this.elRef.nativeElement.getElementsByClassName("notifyicon")[0].parentNode.classList.remove("notifyactive")
      break;
      case 'Guide':
        this.notification_com.clickWidget(false,'guide');
        //this.newNotification=false
        this.elRef.nativeElement.getElementsByClassName("notifyicon_w")[0].parentNode.classList.remove("notifyactive")
      break;
      case 'Notifications Prod':
        this.notification_com.clickWidget(true);
        this.elRef.nativeElement.getElementsByClassName("notifyicon")[1].parentNode.classList.remove("notifyactive")
        //this.newNotification=false
      break;
      case 'Notification':
        if(!env.production)
          window.open('https://dev-notificationadmin.ikea.net/','_blank');
        else
          window.open('https://dev-notificationadmin.ikea.net/','_blank');// prod url will be here
      break;
      case 'SDF':
        window.location.hash='#/home';
      // break;
      // case 'Notification Widget':
      //   window.open('https://notificationwidget.azurewebsites.net/?appCode=SDF','_blank');
      // break;
      // case 'Notification Admin':
      //   window.open('https://dev-notificationadmin.ikea.net/','_blank');
      // break;
      // case 'Notification Center':
      //   window.open('https://notifcenter.azurewebsites.net/?appCode=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6IlNERiIsInN1YiI6IlNERiIsImdpdmVuX25hbWUiOiJTREYiLCJqdGkiOiJmZDY5NzFkZS1iMTY0LTQxMmQtYmUzOC04MDNmYzczMTRlNDMiLCJTY29wZSI6IlNERiIsIm5iZiI6MTYzNjYyNTY4NCwiZXhwIjoxNjM2NjI5Mjg0LCJpYXQiOjE2MzY2MjU2ODQsImlzcyI6Ind3dy5pa2VhLmNvbSIsImF1ZCI6IklrZWFPZlN3ZWRlbiJ9.kMAFR_K3Undyzofh4qEvQGiNoofJjzdGkmvIkYIZDTQ','_blank');
      // break;
      case 'Logout':
        this.un='';this.uni='';
        this.authenticationService.logout();
      break;
      
    }
    //console.log(opt);
  }

  get_cookie(cn) {
    let n = cn + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    //console.log(ca)
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i].split('=');
      if(c[0].indexOf(cn)>-1){
        return c[1];
      }
    }
    return "";
  }

  un:string='';uni:string='';
  user_type(){
    let uit = JSON.parse(localStorage.getItem('user'));
    this.un=uit=='admin'?'UI-SIG':(uit=='demo'?'SDF-UI':'');
    this.uni=uit=='admin'?'SIG':(uit=='demo'?'SDF':'');
    this.footer_data(this.un,this.uni);
  }

  footer:any={};

  footer_data(un:string,uni:string){
    let itm = [{
      text: "Notifications",
      icon: "fa fa-bell notifyicon"
    },
    {
      text: "Guide",
      icon: "fa fa-bell notifyicon_w"
    },
    {
      text: "Logout",
      icon: "fa fa-sign-out-alt"
    }]

    if(uni=='SIG'){
      itm = [{
        text: "Notifications",
        icon: "fa fa-bell notifyicon"
      },
      {
        text: "Guide",
        icon: "fa fa-bell notifyicon_w"
      },
      {
        text: "Notifications Prod",
        icon: "fa fa-bell notifyicon"
      },
      {
        text: "Logout",
        icon: "fa fa-sign-out-alt"
      }]
    }

    this.footer = {
      userName: un,
      initials: uni,
      items: itm
        
    };
  }
  

  handler:any;handler_count:number=0;
  display_user_name(){
    this.call_once_only=true;
    this.handler = setInterval(()=>{
      if(this.get_cookie('super_user')!=''){
        this.footer.userName=this.get_cookie('super_user')=='true'?'UI-SIG':(this.get_cookie('super_user')=='false'?'SDF-UI':'');
        this.footer.initials=this.get_cookie('super_user')=='true'?'SIG':(this.get_cookie('super_user')=='false'?'SDF':'');
      }else{
        this.handler_count++;
        if(this.handler_count>9){
          this.handler_count=0;
          this.call_once_only=false;
          //console.log(this.handler_count)
          clearInterval(this.handler);
        }
      }
    },1500);
  }


  exposeEvent(event) {
    alert('Invoked ' + event);
  }

  search(text) {
    alert('Searched ' + text);
  }
  showpopup(){
    let data = {
      'appCode': 'SDF',//'ToCompete',
      'userId':  JSON.parse(localStorage.getItem('user'))
    }
    this.homeService.getPopupNotification(data).subscribe(res => {
      if(res){
        //this.showNotificationAlert()
        this.notification_com.isPopup = true;
        console.log(res,'popup');
      }
    });
  }

  setWorkerConn(){
    console.log('dsfsdf')
    if (typeof Worker !== 'undefined') {
      // Create a new
      const worker = new Worker('./app.worker', { type: 'module' });
      worker.onmessage = ({ data }) => {
        if(data=='widget'){
          console.log('load widget')
          this.show_notification_once=false;
          this.show_notification();
        }else if(data=='popup'){
          this.showpopup();
        }
        console.log(`page got message: ${data}`);
      };
      worker.postMessage('hello');
    } else {
      // Web Workers are not supported in this environment.
      // You should add a fallback so that your program still executes correctly.
      console.log('not working')
    }
  }
   
   
  
}

