import { ChangeDetectorRef,Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sdf-toast',
  templateUrl: './sdf-toast.component.html',
  styleUrls: ['./sdf-toast.component.css']
})
export class SdfToastComponent implements OnInit {

  constructor(private cdref: ChangeDetectorRef) { }
  isShown =false;
  hide_opt:boolean = false;default_time:number=3;
  toggle_choice:any={
    'type':{
      'Automatically':'success',
      // 'Info':'info',
      'Manual':'error',
      // 'Warning':'warning'
    },
    'horizontalPosition':{
      'Left':'left',
      'Center':'center',
      'Right':'right'
    },
    'verticalPosition':{
      'Top':'top',
      'Bottom':'bottom'
    }
  }

  toggle_o_selected:any={
    'type':'success',
    'horizontalPosition':'center',
    'verticalPosition':'top'
  }
  showme:boolean=true;
  ngOnInit(): void {
  }
  i_repeat:number=0;hide_component:boolean=false;
  toggle_selected(val,v){
    this.i_repeat++;
    if(this.i_repeat>2){
      this.isShown =true;
      this.toggle_o_selected[v]=this.toggle_choice[v][val];
      
    }
    
  }


  preview(){
    this.hide_component=false;
    setTimeout(()=>{
      this.hide_component=true;
    },2)

    setTimeout(()=>{
      let el = document.getElementsByClassName('toastMessage') as HTMLCollection;
      for(let i=0;i<el.length;i++){
        let ell = el[i] as HTMLDivElement
        ell.style.display='none';
      }
    },this.default_time*1000+500)
  }

  clicked(){
    console.log('button clicked')
  }
  showToast(){
    this.isShown =true;
  }
  ngAfterContentChecked() {

    this.cdref.detectChanges();

  }
}
