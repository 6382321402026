import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sdf-radio-sample',
  templateUrl: './sdf-radio-sample.component.html',
  styleUrls: ['./sdf-radio-sample.component.css']
})
export class SdfRadioSampleComponent implements OnInit {
  flowRadioDisabledItems: any;
  flowRadioListItems: any;
  selectedValue:any= {
    value: 'd',
    text: 'Option',
    disabled: false
  };
  selectedValue1:any= {
    value: 'a',
    text: 'Option',
    disabled: false
  };
  selectedDisabledValue:any= {
    value: 'd',
    text: 'Option',
    disabled: true
  };
  selectedDisabledValue1:any= {
    value: 'a',
    text: 'Option',
    disabled: false
  };
  constructor() { }

  ngOnInit(): void {
    this.flowRadioListItems = [

      {
        value: 'a',
        text: 'Option',
        disabled: false,
        checked: false
      },
      {
        value: 'd',
        text: 'Option',
        disabled: false,
        checked: true
      }
    ];
    this.flowRadioDisabledItems = [

      {
        value: 'a',
        text: 'Option',
        disabled: true,
        checked: false
      },
      {
        value: 'd',
        text: 'Option',
        disabled: true,
        checked: true
      }
    ];
  }

  onChange(event) {
  }

}
