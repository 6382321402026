import { ChangeDetectorRef, Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sdf-datepicker',
  templateUrl: './sdf-datepicker.component.html',
  styleUrls: ['./sdf-datepicker.component.css']
})
export class SdfDatepickerComponent implements OnInit {

  constructor(private cdref: ChangeDetectorRef) { }

  hide_opt:boolean = false;hide_code:boolean=true;
  toggle_choice:any={
    'label':{
      'On Frame':'frame',
      'Outside':'outside'
    },
    'readonly':{
      'Yes':'yes',
      'No':'no'
    },
    'disable':{
      'Yes':'yes',
      'No':'no'
    },
  }

  toggle_o_selected:any={
    'label':'frame',
    'readonly':'no',
    'disable':'no',
  }
  public value: Date = new Date();
  public startDate: any = new Date();

  oldDateObj = new Date();
  maxDate:number = 8*24*60 * 60 * 1000;
  ngOnInit(): void {
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  i_repeat:number=0;hide_component:boolean=true;update_disable:boolean=true;
  toggle_selected(val,v){

    // if((v=='error' && val=='Yes') || this.toggle_o_selected['error']=='yes'){
    //   this.toggle_o_selected['disable']='no';
    //   this.toggle_o_selected['state']='Unselected';
    // }
    
    this.i_repeat++;
    if(this.i_repeat>2){
      if(v!='allowed_date')
        this.toggle_o_selected[v]=this.toggle_choice[v][val];
      else{
        switch(val){
          case 'All':
            this.startDate='';
            this.maxDate=0;
            break;
          case 'Future':
            this.startDate=new Date();
            this.maxDate=0;
          break;
          case 'Historic':
            this.startDate='';
            this.maxDate=1;
          break;
          case 'Weekdays':
            this.startDate=new Date();
            this.maxDate=7*24*60 * 60 * 1000;
        }
      }
      this.hide_component=false;this.update_disable=false;
      setTimeout(()=>{
        this.hide_component=true;this.update_disable=true;
      },2)
    }
    
  }

  onFocus(event) {
    console.log(event);
  }
  copy_code(eli:any){
    eli = 'rtyur';//this.toggle_o_selected['thumbnail']=='none'?'rtyu':'rtyur';
    let el = document.getElementById(eli) as HTMLElement;
    let da = el.innerHTML.replace(/&lt;/g,'<');
    da = da.replace(/&gt;/g,'>')
    navigator.clipboard.writeText(da);
    console.log(da)
  }
}
