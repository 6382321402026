import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sdf-notification-system',
  templateUrl: './sdf-notification-system.component.html',
  styleUrls: ['./sdf-notification-system.component.css']
})
export class SdfNotificationSystemComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  open_center(){
    window.open('https://notifcenter.azurewebsites.net/?appCode=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6IlNERiIsInN1YiI6IlNERiIsImdpdmVuX25hbWUiOiJTREYiLCJqdGkiOiJmZDY5NzFkZS1iMTY0LTQxMmQtYmUzOC04MDNmYzczMTRlNDMiLCJTY29wZSI6IlNERiIsIm5iZiI6MTYzNjYyNTY4NCwiZXhwIjoxNjM2NjI5Mjg0LCJpYXQiOjE2MzY2MjU2ODQsImlzcyI6Ind3dy5pa2VhLmNvbSIsImF1ZCI6IklrZWFPZlN3ZWRlbiJ9.kMAFR_K3Undyzofh4qEvQGiNoofJjzdGkmvIkYIZDTQ','_blank');
  }

  open_widget(){
    window.open('https://dev-notificationadmin.ikea.net/','_blank');
  }

}
