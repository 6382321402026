import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sdf-cloud-infra',
  templateUrl: './sdf-cloud-infra.component.html',
  styleUrls: ['./sdf-cloud-infra.component.css']
})
export class SdfCloudInfraComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  private showChatbot:boolean = false;
  clicked(){
    console.log('check')
    this.showChatbot = !this.showChatbot;
  }

}
