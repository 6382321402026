import { Component, OnInit } from '@angular/core';
import {ODataSearchService} from '../../../core/services/o-data-search.service'

@Component({
  selector: 'app-sdf-single-autocomplete-sample',
  templateUrl: './sdf-single-autocomplete-sample.component.html',
  styleUrls: ['./sdf-single-autocomplete-sample.component.scss']
})
export class SdfSingleAutocompleteSampleComponent implements OnInit {
  data_me:any;
  // data to be passed as json in singe select auto complete
  data:any=[];
  // [
  //   "Algeria",
  //   "Andorra",
  //   " Angola",
  //   "Anguilla",
  //   "Antigua & Barbuda",
  //   "Argentina",
  //   "Armenia",
  //   "Australia",
  //   "Austria",
  //   "Bermuda",
  //   "Bhutan",
  //   "Bolivia",
  //   "Bosnia & Herzegovina",
  //   "Botswana",
  //   "Brazil",
  //   "Brunei Darussalam",
  //   "Bulgaria",
  //   "Burkina Faso",
  //   "Canada",
  //   "Cape Verde",
  //   "Cayman Islands",
  //   "Central African Republic",
  //   "Chad",
  //   "Chile",
  //   "China",
  //   "China - Hong Kong / Macau",
  //   "Colombia",
  //   "Comorosa",
  //   "Eritrea",
  //   "Estonia",
  //   "Finland",
  //   "France",
  //   "French Guiana",
  //   "Georgia",
  //   "Germany",
  //   "Ghana",
  //   "Greece",
  //   "Grenada",
  //   "Guatemala",
  //   "Guinea",
  //   "Guinea-Bissau",
  //   "Guyana",
  //   "Haiti",
  //   "Honduras",
  //   "Hungary",
  //   "Iceland",
  //   "India",
  //   "India",
  //   "Indonesia",
  //   "Iran",
  //   "Jamaica",
  //   "Japan",
  //   "Jordan",
  //   "Kazakhstan",
  //   "Kuwait",
  //   "Kyrgyz Republic (Kyrgyzstan)",
  //   "Lebanon",
  //   "Lesotho",
  //   "Malawi",
  //   "Malaysia",
  //   "Nepal",
  //   "Netherlands",
  //   "New Zealand",
  //   "Oman",
  //   "Pacific Islands",
  //   "Pakistan",
  //   "Panama",
  //   "Portugal",
  //   "Puerto Rico",
  //   "Qatar",
  //   "Reunion",
  //   "Romania",
  //   "Russian Federation",
  //   "Rwanda",
  //   "Saint Kitts and Nevis",
  //   "Saint Lucia",
  //   "Saint Vincent and the Grenadines",
  //   "Singapore",
  //   "Slovak Republic (Slovakia)",
  //   "Slovenia",
  //   "Solomon Islands",
  //   "Spain",
  //   "Sri Lanka",
  //   "Sweden",
  //   "Switzerland",
  //   "Syria",
  //   "Turkey",
  //   "Uruguay",
  //   "Uzbekistan",
  //   "Venezuela",
  //   "Vietnam",
  //   "Virgin Islands (UK)",
  //   "Virgin Islands (US)",
  //   "Yemen",
  //   "Zambia",
  //   "Zimbabwe",
  //   'demo'
  //  ]

  loading_sign:boolean=false;// loading spinner will be shown if set to true

  constructor(private ODataSearchService: ODataSearchService) { }

  ngOnInit() {
    this.ODataSearchService.getCountires().subscribe(res =>{
      this.data=res;
    })
  }

  valueChanged(object){
    // object is selected object -- object.value, object.ref_value, object.extra_val
    console.log(object);
    
  }

  valueCleared(){
    console.log('value cleared');
  }

}
