"use strict";
import { Injectable } from '@angular/core';

import {Router, CanActivate, CanActivateChild, CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import {AuthenticationService} from '../services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate //, CanActivateChild, CanLoad 
{
  constructor(private router: Router,private authenticationService: AuthenticationService)
  {

  }

  get_cookie(cn) {
    let n = cn + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    //console.log(ca)
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i].split('=');
      if(c[0].indexOf(cn)>-1){
        return c[1];
      }
    }
    return "";
  }

  canActivate(route: ActivatedRouteSnapshot,state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const currentUser=this.authenticationService.userId;//userData
    let hsh = window.location.hash;
    //const currentUser=localStorage.getItem('user')
    //console.log("currentUser",this.authenticationService)
    //console.log("currentUser",currentUser)
    //console.log("localStorage",localStorage.getItem('user'))
    if(currentUser){
      return true;
    }else{
        //return true;
      // validate user by api
      if(this.get_cookie('sid')){
        let data = {
          'sessionId':this.get_cookie('sid')
        }
        this.authenticationService.session_login(data).subscribe(res=>{
          //console.log(res);
          if(res.sessionId){
            document.cookie ='sid='+res.sessionId;
            this.authenticationService.userData = JSON.parse(localStorage.getItem('user'));
            this.authenticationService.userId = res.result;
            console.log(this.authenticationService.userId,'dddd')
            this.authenticationService.sendUserDetails(JSON.parse(localStorage.getItem('user')))
            window.location.href='/'+hsh;
            return true;
            //this.router.navigate(['/sdf-button']);
          }else{
            //this.router.navigate(['/login']);
            //window.location.href='/';
            this.router.navigate(['/login']);
            return false;
          }
        })
      }else{
        //window.location.href='/';
        this.router.navigate(['/login']);
        return false;
      }
      
    }
    
  }
 
}
