import { Component, OnInit } from '@angular/core';
import { SdfBannerService, SdfAlertsService } from '@sdf/sdf-ui';

@Component({
  selector: 'app-sdf-banner-sample',
  templateUrl: './sdf-banner-sample.component.html',
  styleUrls: ['./sdf-banner-sample.component.scss']
})
export class SdfBannerSampleComponent implements OnInit {

  constructor(private sdfBannerService: SdfBannerService, private sdfAlertService: SdfAlertsService) { }

  ngOnInit() {
  }

  showDialog(data) {
    let bannerData = {
      "title": "SDF_UI",
      "navBar": "vertical",
      "description": "Labore deserunt nisi commodo velit labore labore occaecat commodo consectetur. Labore deserunt nisi commodo velit labore labore occaecat commodo consectetur.",
      "type": "",
      "action": {}
    } 
    bannerData.type = data;
    if(data == 'default'){
      bannerData.action = {
        "btnPrimary": "Confirm",
        "btnSecondary": "Cancel",
      }
    }
    else{
      bannerData.action = {
        "readMore": {
          "path": "https://www.google.com"
        }
      }
    }
    this.sdfBannerService.confirmThis(bannerData, function () {  
      console.log('Confirm');
    }, function () {  
      console.log('Cancel');
    })  
  }  

  showAlert(){
    let alertData = {
      "title": "SDF_UI",
      "description": "Commodo consequat amet cillum non ullamco et non et anim velit anim mollit laborum commodo. Culpa incididunt nulla veniam culpa Lorem do voluptate quis. Veniam Lorem officia culpa officia laborum aliquip. Culpa dolor duis amet qui quis sint id esse.",
      "action": {
        "btnPrimary": "Confirm",
        "btnSecondary": "Cancel",
      }
    }
    this.sdfAlertService.confirmThis(alertData, function () {  
      console.log('Confirm');
    }, function () {  
      console.log('Cancel');
    }) 
  }

}
