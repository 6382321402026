import { Component, ElementRef, HostListener, Input, OnInit, Renderer2 } from '@angular/core';

import { PageEditingService } from './../dynamic_editing/page-editing.service';

@Component({
  selector: 'app-dynamic-nav-bar',
  templateUrl: './dynamic-nav-bar.component.html',
  styleUrls: ['./dynamic-nav-bar.component.css']
})
export class DynamicNavBarComponent implements OnInit {

  @Input('component') component:string='test';
  @Input('heading') heading:string='Demo';
  @Input('demo_bool') demo_bool:boolean=true;


  component_Section_po:any={};

  @HostListener('window:scroll', ['$event']) 
    doSomething(event) {
      // console.debug("Scroll Event", document.body.scrollTop);
      // see András Szepesházi's comment below
      if(Object.keys(this.component_Section_po).length <2)
        this.figure_out_cp();
      else{
        // highlight nav options
        let called=false;
        const reversedKeys = Object.keys(this.component_Section_po).reverse();
        reversedKeys.forEach(key => {
          if(window.pageYOffset +90 > this.component_Section_po[key] && called ==false){
            called =true;
            this.nav_selected('',key,false);
            
          }
          //console.log(key, obj[key]); // 👉️ c three, b two, a one
        })

      }
  }

  constructor(private pageeditingservice:PageEditingService,private renderer: Renderer2) { }

  // api is called for loading section title and link options

  nav_bar:any=[];link:any=[];display_to:boolean=false;
  ngOnInit(): void {

    this.pageeditingservice.get_component_links(this.component).subscribe(
      (response)=>{
        this.link=response;
        this.pageeditingservice.get_component_section(this.component).subscribe(
          (response)=>{
            this.nav_bar=response;
            this.display_to=this.nav_bar.length>0?true:false;
            window.scrollTo(0,0);
            if(this.demo_bool==false && this.nav_bar.length>0){
              let id = response[this.nav_bar.length-1].sectionTitle;
              setTimeout(()=>{this.nav_selected('',id);},1000);
            }
          },
          (error)=>{console.log(error)}
        )
      },
      (error)=>{console.log(error)}
    )

    
  }

  figure_out_cp(){
    let el:HTMLCollection = document.getElementsByClassName('component_Section');
    for(let i =0;i<el.length;i++){
      let ell = el[i] as HTMLElement;
      this.component_Section_po[ell.id] = ell.offsetTop;
    }
  }

  show_index:boolean=false;
  nav_selected(event='',id,what=true){
    this.clear_border();
    //this.renderer.addClass(event.target,"border_bottom");
    //event.target.classList.add('border_bottom');
    let id1 = id+'_id';
    let el = document.getElementById(id1) as HTMLElement;
    let el1 = document.getElementById(id) as HTMLElement,h=el1.getBoundingClientRect();
    el.classList.add('border_bottom');
    if(what){
      if(id=='demo')
        window.scrollTo(0,0);
      else
        window.scrollTo(0,h.top+window.scrollY-80);//el1.scrollIntoView({'behavior':'smooth','block':'start','inline':'start'});
    }
    this.show_index=true;
  }

  clear_border(){
    let el:HTMLCollection = document.getElementsByClassName('nav_bar_lii');
    //console.log(el.length)
    for(let i=0;i<el.length;i++){
      let ell = el[i] as HTMLElement;
      //this.renderer.removeClass(ell,'border_bottom')
      ell.classList.remove('border_bottom');
      ell.children[0].classList.remove('border_bottom')
    }
  }

}
