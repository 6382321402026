import { Component, OnInit, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-sdf-tooltip',
  templateUrl: './sdf-tooltip.component.html',
  styleUrls: ['./sdf-tooltip.component.css']
})
export class SdfTooltipComponent implements OnInit {

  constructor(private cdref: ChangeDetectorRef) { }
  hide_opt:boolean = false;
  tooltipData={ 
    position: "top",
    title: "Tooltip Header", 
    content:"Tooltip Content may of multiple lines of rows" ,
    tooltipItem:"button"
    }
  public staticData =[
    {CompanyName:'ALFKI',ContactName:'Maria Anders', Button1:{icon:'fas fa-calendar',disabled:false},Button2:{icon:'fas fa-edit',disabled:false}},
    {CompanyName:'ANATR',ContactName:'Ana Trujillo',Button1:{icon:'fas fa-play',disabled:false},Button2:{icon:'fas fa-save',disabled:false}},
    {CompanyName:'ANTON',ContactName:'Antonio Moreno',Button1:{icon:'fas fa-eye',disabled:false},Button2:{icon:'fas fa-edit',disabled:false}},
    {CompanyName:'CACTU',ContactName:'Comidas para Anders', Button1:{icon:'fas fa-calendar',disabled:true},Button2:{icon:'fas fa-save',disabled:true}},
    {CompanyName:'LAXSS',ContactName:'Mary Sanders', Button1:{icon:'fas fa-play',disabled:true},Button2:{icon:'fas fa-edit',disabled:true}},
    {CompanyName:'MAFRS',ContactName:'Cactus Comidas para', Button1:{icon:'fas fa-eye',disabled:true},Button2:{icon:'fas fa-save',disabled:true}},
    {CompanyName:'QEETW',ContactName:'Tesla Anders', Button1:{icon:'fas fa-calendar',disabled:false},Button2:{icon:'fas fa-edit',disabled:false}},
    {CompanyName:'PLLOO',ContactName:'Comidas para Anders', Button1:{icon:'fas fa-calendar',disabled:false},Button2:{icon:'fas fa-save',disabled:false}},
    {CompanyName:'YUIO',ContactName:'Maria Anders', Button1:{icon:'fas fa-calendar',disabled:false},Button2:{icon:'fas fa-edit',disabled:false}},
    {CompanyName:'VBMH',ContactName:'Cactus Comidas para', Button1:{icon:'fas fa-calendar',disabled:true},Button2:{icon:'fas fa-play',disabled:true}},
    {CompanyName:'MAFRS',ContactName:'Cactus Comidas para', Button1:{icon:'fas fa-calendar',disabled:false},Button2:{icon:'fas fa-save',disabled:false}},
    {CompanyName:'QEETW',ContactName:'Tesla Anders', Button1:{icon:'fas fa-calendar',disabled:false},Button2:{icon:'fas fa-edit',disabled:false}},
    {CompanyName:'PLLOO',ContactName:'Comidas para Anders', Button1:{icon:'fas fa-play',disabled:false},Button2:{icon:'fas fa-calendar',disabled:false}},
    {CompanyName:'YUIO',ContactName:'Maria Anders', Button1:{icon:'fas fa-calendar',disabled:false},Button2:{icon:'fas fa-save',disabled:false}},
    {CompanyName:'VBMH',ContactName:'Cactus Comidas para', Button1:{icon:'fas fa-calendar',disabled:true},Button2:{icon:'fas fa-edit',disabled:true}},
    {CompanyName:'IORE',ContactName:'Maria Anders', Button1:{icon:'fas fa-calendar',disabled:false},Button2:{icon:'fas fa-edit',disabled:false}},
    {CompanyName:'BNB',ContactName:'Cactus Comidas para', Button1:{icon:'fas fa-calendar',disabled:false},Button2:{icon:'fas fa-save',disabled:false}},
  ]
  toggle_choice:any={
    'type':{
      'Primary':'primary',
      'Secondar':'secondary',
      'Text':'text',
      'Icon':'icon',
      'Grid':'grid',
      'Tab':'tab',
    },
    'gridType':{
      'Default':'default'
    },
    'width':{
      'Fluid':'fluid',
      'Auto':'auto'
    },
    'state':{
      'Noraml':'normal',
      'Disable':'disable'
    },
    'header':{
      'Yes':'yes',
      'No':'no'
    },
    'position':{
      'Left':'left',
      'Right':'right',
      'Top':'top',
      'Bottom':'bottom'
    }
  }

  toggle_o_selected:any={
    'type':'primary',
    
  }
  ngOnInit(): void {
  }
  i_repeat:number=0;hide_component:boolean=true;
  toggle_selected(val,v){
    //this.i_repeat++;
    //if(this.i_repeat>4){
      this.toggle_o_selected[v]=this.toggle_choice[v][val];
      console.log(this.toggle_o_selected);
      if(this.toggle_o_selected.header=='yes'){
        this.tooltipData['title']= "Tooltip Header"
      }else{
        delete this.tooltipData['title']
      }
      if(this.toggle_o_selected.position=='left'){
        this.tooltipData['position']="left"
      }else if(this.toggle_o_selected.position=='right'){
        this.tooltipData['position']="right"
      }else if(this.toggle_o_selected.position=='top'){
        this.tooltipData['position']="top"
      }else if(this.toggle_o_selected.position=='bottom'){
        this.tooltipData['position']="bottom"
      }
      this.hide_component=false;
      setTimeout(()=>{
        this.hide_component=true;
      },2)
    //}
    
  }

  clicked(){
    console.log('button clicked')
  }
  ngAfterContentChecked() {

    this.cdref.detectChanges();

  }

}
