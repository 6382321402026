import { ChangeDetectorRef, Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sdf-tabs',
  templateUrl: './sdf-tabs.component.html',
  styleUrls: ['./sdf-tabs.component.css']
})
export class SdfTabsComponent implements OnInit {

  constructor(private cdref: ChangeDetectorRef) { }

  hide_opt:boolean = false;
  toggle_choice:any={
    'size':{
      '14px':'small',
      '16px':'medium',
      '18px':'large'
    },
    'type':{
      'Text':'text',
      'Icon':'icon',
      'Icon+text':'icontext'
    },
    'border':{
      'Yes':'yes',
      'No':'no'
    }
  }

  toggle_o_selected:any={
    'size':'medium',
    'type':'text',
    'border':'no'
  }

  sampletext:string="Sample Text";
  ngOnInit(): void {
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  i_repeat:number=0;hide_component:boolean=true;
  toggle_selected(val,v){

    this.i_repeat++;
    if(this.i_repeat>3){
      this.toggle_o_selected[v]=this.toggle_choice[v][val];
      console.log(this.toggle_o_selected);
      this.hide_component=false;
      setTimeout(()=>{
        this.hide_component=true;
      },2)
    }
    
  }

}
