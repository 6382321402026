import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {
  SdfTextboxModule,
  SdfButtonModule,
  SdfNewButtonModule,
  SdfGridModule,
  SdfMenuModule,
  SdfSelectModule,
  SdfTabsModule,
  SdfHorizontalMenuModule,
  SdfAlertsModule,
  SdfBannerModule,
  SdfPillsModule,
  SdfAccordionModule,
  SdfSwitchModule,
  SdfToggleModule,
  SdfInlineMessageModule,
  SdfTooltipModule,
  SdfSingleAutocompleteModule,
  SdfMultiAutocompleteModule,
  SdfNewPillsModule,
  SdfSingleDropdownModule,
  SdfMultiDropdownModule,
  SdfFileUploadModule,
  SdfToastModule,
  SdfCheckboxModule,
  SdfMultiHierarchyAutocompleteModule,
  SdfMultiHierarchyDropdownModule,
  SdfSingleHierarchyAutocompleteModule,
  SdfSingleHierarchyDropdownModule,
  SdfNewToggleModule,
  SdfRichTextModule,
  SdfNewEditorModule,
  SdfNewBannerModule
}
  from '@sdf/sdf-ui';

import { SdfMenuSampleComponent } from './sdf-samples/sdf-menu-sample/sdf-menu-sample.component';
import { SdfButtonSampleComponent } from './sdf-samples/sdf-button-sample/sdf-button-sample.component';
import { SdfTextareaSampleComponent } from './sdf-samples/sdf-textarea-sample/sdf-textarea-sample.component';
import { SdfGridSampleComponent } from './sdf-samples/sdf-grid-sample/sdf-grid-sample.component';
import { SdfTextboxSampleComponent } from './sdf-samples/sdf-textbox-sample/sdf-textbox-sample.component';
import { SdfSelectSampleComponent } from './sdf-samples/sdf-select-sample/sdf-select-sample.component';
import { SdfDatepickerSampleComponent } from './sdf-samples/sdf-datepicker-sample/sdf-datepicker-sample.component';
import { SdfTabsSampleComponent } from './sdf-samples/sdf-tabs-sample/sdf-tabs-sample.component';
import { HomeComponent, LoginComponent } from './sdf-samples/modules';
import { SdfTooltipSampleComponent } from './sdf-samples/sdf-tooltip-sample/sdf-tooltip-sample.component';
import { SdfHorizontalSampleComponent } from './sdf-samples/sdf-horizontal-sample/sdf-horizontal-sample.component';
import { SdfBannerSampleComponent } from './sdf-samples/sdf-banner-sample/sdf-banner-sample.component';
import { SdfPillsSampleComponent } from './sdf-samples/sdf-pills-sample/sdf-pills-sample.component';
import { SdfControlsComponent } from './sdf-samples/sdf-controls/sdf-controls.component';
import { SdfAccordionSampleComponent } from './sdf-samples/sdf-accordion/sdf-accordion-sample.component';
import { SdfSwitchSampleComponent } from './sdf-samples/sdf-switch-sample/sdf-switch-sample.component';
import { SdfToggleSampleComponent } from './sdf-samples/sdf-toggle-sample/sdf-toggle-sample.component';
import { SdfAutocompleteSampleComponent } from './sdf-samples/sdf-autocomplete-sample/sdf-autocomplete-sample.component';
import { SdfDropdownSampleComponent } from './sdf-samples/sdf-dropdown-sample/sdf-dropdown-sample.component';
import { SdfCheckboxSampleComponent } from './sdf-samples/sdf-checkbox-sample/sdf-checkbox-sample.component';
import { SdfRadioSampleComponent } from './sdf-samples/sdf-radio-sample/sdf-radio-sample.component';
import { SdfInlineMessageSampleComponent } from './sdf-samples/sdf-inline-message-sample/sdf-inline-message-sample.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
//import { SdfSingleAutocompleteSampleComponent } from './sdf-samples/sdf-single-autocomplete-sample/sdf-single-autocomplete-sample.component';
//import { SdfNewPillsSampleComponent } from './sdf-samples/sdf-new-pills-sample/sdf-new-pills-sample.component';
//import { SdfNewButtonSampleComponent } from './sdf-samples/sdf-new-button-sample/sdf-new-button-sample.component';
//import { SdfMultiAutocompleteSampleComponent } from './sdf-samples/sdf-multi-autocomplete-sample/sdf-multi-autocomplete-sample.component';



import { MsalInterceptor, MsalModule } from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { SdfNewButtonSampleComponent } from './sdf-samples/sdf-new-button-sample/sdf-new-button-sample.component';
import { SdfSingleAutocompleteSampleComponent } from './sdf-samples/sdf-single-autocomplete-sample/sdf-single-autocomplete-sample.component';
import { SdfMultiAutocompleteSampleComponent } from './sdf-samples/sdf-multi-autocomplete-sample/sdf-multi-autocomplete-sample.component';
import { SdfNewPillsSampleComponent } from './sdf-samples/sdf-new-pills-sample/sdf-new-pills-sample.component';
import { SdfSingleDropdownSampleComponent } from './sdf-samples/sdf-single-dropdown-sample/sdf-single-dropdown-sample.component';
import { SdfMultiDropdownSampleComponent } from './sdf-samples/sdf-multi-dropdown-sample/sdf-multi-dropdown-sample.component';
import { SdfInlineAlertSampleComponent } from './sdf-samples/sdf-inline-alert-sample/sdf-inline-alert-sample.component';
import { SdfAlertModalSampleComponent } from './sdf-samples/sdf-alert-modal-sample/sdf-alert-modal-sample.component';
import { SdfIconButtonSampleComponent } from './sdf-samples/sdf-icon-button-sample/sdf-icon-button-sample.component';
import { SdfTableSortingSampleComponent } from './sdf-samples/sdf-table-sorting-sample/sdf-table-sorting-sample.component';
import { SdfTableCommonFilterSampleComponent } from './sdf-samples/sdf-table-common-filter-sample/sdf-table-common-filter-sample.component';
import { SdfTableFilterRowSampleComponent } from './sdf-samples/sdf-table-filter-row-sample/sdf-table-filter-row-sample.component';
import { SdfTableSingleSelectionSampleComponent } from './sdf-samples/sdf-table-single-selection-sample/sdf-table-single-selection-sample.component';
import { SdfTableMultipleSelectionSampleComponent } from './sdf-samples/sdf-table-multiple-selection-sample/sdf-table-multiple-selection-sample.component';
import { SdfFileUploadSampleComponent } from './sdf-samples/sdf-file-upload/sdf-file-upload-sample.component';
import { SdfSingleHierarchyAutocompleteSampleComponent } from './sdf-samples/sdf-single-hierarchy-autocomplete-sample/sdf-single-hierarchy-autocomplete-sample.component';
import { SdfSingleHierarchyDropdownSampleComponent } from './sdf-samples/sdf-single-hierarchy-dropdown-sample/sdf-single-hierarchy-dropdown-sample.component';
import { SdfMultiHierarchyAutocompleteSampleComponent } from './sdf-samples/sdf-multi-hierarchy-autocomplete-sample/sdf-multi-hierarchy-autocomplete-sample.component';
import { SdfMultiHierarchyDropdownSampleComponent } from './sdf-samples/sdf-multi-hierarchy-dropdown-sample/sdf-multi-hierarchy-dropdown-sample.component';
import { SdfNewToggleSampleComponent } from './sdf-samples/sdf-new-toggle-sample/sdf-new-toggle-sample.component';
import { SdfNewAutocompleteSampleComponent } from './sdf-samples/sdf-new-autocomplete-sample/sdf-new-autocomplete-sample.component';
import { EditOptionsComponent } from './sdf-samples/dynamic_editing/edit-options/edit-options.component';
import { DynamicNavBarComponent } from './sdf-samples/dynamic-nav-bar/dynamic-nav-bar.component';
import { SdfNewDropdownComponent } from './sdf-samples/sdf-new-dropdown/sdf-new-dropdown.component';
import { SdfButtonComponent } from './sdf-samples/sdf-button/sdf-button.component';
import { SdfPillsComponent } from './sdf-samples/sdf-pills/sdf-pills.component';
//import { SdfIconButtonComponent } from './sdf-samples/sdf-icon-button/sdf-icon-button.component';
import { SdfTableComponent } from './sdf-samples/sdf-table/sdf-table.component';
import { SdfInlineMessageComponent } from './sdf-samples/sdf-inline-message/sdf-inline-message.component';
import { SdfBannerComponent } from './sdf-samples/sdf-banner/sdf-banner.component';
import { SdfToastComponent } from './sdf-samples/sdf-toast/sdf-toast.component';
import { SdfCheckboxComponent } from './sdf-samples/sdf-checkbox/sdf-checkbox.component';
import { SdfRadioComponent } from './sdf-samples/sdf-radio/sdf-radio.component';
import { SdfSwitchComponent } from './sdf-samples/sdf-switch/sdf-switch.component';
import { SdfToggleComponent } from './sdf-samples/sdf-toggle/sdf-toggle.component';
import { SdfTooltipComponent } from './sdf-samples/sdf-tooltip/sdf-tooltip.component';
import { SdfDatepickerComponent } from './sdf-samples/sdf-datepicker/sdf-datepicker.component';
import { SdfTextboxComponent } from './sdf-samples/sdf-textbox/sdf-textbox.component';
import { SdfSearchComponent } from './sdf-samples/sdf-search/sdf-search.component';
import { SdfVerticalMenuComponent } from './sdf-samples/sdf-vertical-menu/sdf-vertical-menu.component';
import { SdfHorizontalMenuComponent } from './sdf-samples/sdf-horizontal-menu/sdf-horizontal-menu.component';
import { SdfTabsComponent } from './sdf-samples/sdf-tabs/sdf-tabs.component';
import { SdfMenuComponent } from './sdf-samples/sdf-menu/sdf-menu.component';
import { SdfColourComponent } from './sdf-samples/sdf-colour/sdf-colour.component';
import { SdfTypographyComponent } from './sdf-samples/sdf-typography/sdf-typography.component';
import { SdfIconographyComponent } from './sdf-samples/sdf-iconography/sdf-iconography.component';
import { SdfCloudInfraComponent } from './sdf-samples/sdf-cloud-infra/sdf-cloud-infra.component';
import { SdfBackendComponent } from './sdf-samples/sdf-backend/sdf-backend.component';
import { SdfContactComponent } from './sdf-samples/sdf-contact/sdf-contact.component';
import { UiHomeComponent } from './sdf-samples/ui-home/ui-home.component';
import { SdfNotificationWidgetComponent } from './sdf-samples/sdf-notification-widget/sdf-notification-widget.component';
import { SdfNotificationCenterComponent } from './sdf-samples/sdf-notification-center/sdf-notification-center.component';
import { SdfNotificationAdminComponent } from './sdf-samples/sdf-notification-admin/sdf-notification-admin.component';
import { SdfNotificationSystemComponent } from './sdf-samples/sdf-notification-system/sdf-notification-system.component';
import { SdfNewBannerComponent } from './sdf-samples/sdf-new-banner/sdf-new-banner.component';
import { SkapaWcButtonComponent } from './skapa-wc-button/skapa-wc-button.component';

import "@ingka/accordion-webc";
import "@ingka/toggle-webc";
import { TestPageComponent } from './test-page/test-page.component';

const isIE = window.navigator.userAgent.indexOf('MSIE') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;


@NgModule({
  declarations: [
    AppComponent,
    SdfMenuSampleComponent,
    SdfButtonSampleComponent,
    SdfGridSampleComponent,
    SdfTextboxSampleComponent,
    SdfTextareaSampleComponent,
    SdfSelectSampleComponent,
    SdfDatepickerSampleComponent,
    SdfTabsSampleComponent,
    SdfMenuSampleComponent,
    SdfTooltipSampleComponent,
    SdfHorizontalSampleComponent,
    SdfBannerSampleComponent,
    SdfControlsComponent,
    HomeComponent,
    LoginComponent,
    SdfPillsSampleComponent,
    SdfAccordionSampleComponent,
    SdfSwitchSampleComponent,
    SdfToggleSampleComponent,
    SdfAutocompleteSampleComponent,
    SdfDropdownSampleComponent,
    SdfCheckboxSampleComponent,
    SdfRadioSampleComponent,
    SdfInlineMessageSampleComponent,
    SdfSingleAutocompleteSampleComponent,
    SdfNewPillsSampleComponent,
    SdfNewButtonSampleComponent,
    SdfMultiAutocompleteSampleComponent,
    SdfSingleDropdownSampleComponent,
    SdfMultiDropdownSampleComponent,
    SdfInlineAlertSampleComponent,
    SdfAlertModalSampleComponent,
    SdfIconButtonSampleComponent,
    SdfTableSortingSampleComponent,
    SdfTableCommonFilterSampleComponent,
    SdfTableFilterRowSampleComponent,
    SdfTableSingleSelectionSampleComponent,
    SdfTableMultipleSelectionSampleComponent,
    SdfFileUploadSampleComponent,
    SdfSingleHierarchyAutocompleteSampleComponent,
    SdfSingleHierarchyDropdownSampleComponent,
    SdfMultiHierarchyAutocompleteSampleComponent,
    SdfMultiHierarchyDropdownSampleComponent,
    SdfTableMultipleSelectionSampleComponent,
    SdfNewToggleSampleComponent,
    SdfNewAutocompleteSampleComponent,
    SdfNewAutocompleteSampleComponent,
    EditOptionsComponent,
    DynamicNavBarComponent,
    SdfNewDropdownComponent,
    SdfButtonComponent,
    SdfPillsComponent,
    //SdfIconButtonComponent,
    SdfTableComponent,
    SdfInlineMessageComponent,
    SdfBannerComponent,
    SdfToastComponent,
    SdfCheckboxComponent,
    SdfRadioComponent,
    SdfSwitchComponent,
    SdfToggleComponent,
    SdfTooltipComponent,
    SdfDatepickerComponent,
    SdfTextboxComponent,
    SdfSearchComponent,
    SdfVerticalMenuComponent,
    SdfHorizontalMenuComponent,
    SdfTabsComponent,
    SdfMenuComponent,
    SdfColourComponent,
    SdfTypographyComponent,
    SdfIconographyComponent,
    SdfCloudInfraComponent,
    SdfBackendComponent,
    SdfContactComponent,
    UiHomeComponent,
    SdfNotificationWidgetComponent,
    SdfNotificationCenterComponent,
    SdfNotificationAdminComponent,
    SdfNotificationSystemComponent,
    SdfNewBannerComponent,
    SkapaWcButtonComponent,
    TestPageComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SdfTextboxModule,
    SdfButtonModule,
    SdfBannerModule,
    SdfAlertsModule,
    SdfGridModule,
    SdfMenuModule,
    SdfHorizontalMenuModule,
    SdfSelectModule,
    SdfTabsModule,
    SdfPillsModule,
    SdfAccordionModule,
    SdfSwitchModule,
    SdfToggleModule,
    SdfInlineMessageModule,
    BrowserAnimationsModule,
    SdfTooltipModule,
    SdfNewButtonModule,
    SdfSingleAutocompleteModule,
    SdfMultiAutocompleteModule,
    SdfSingleDropdownModule,
    SdfMultiDropdownModule,
    SdfNewPillsModule,
    SdfFileUploadModule,
    SdfToastModule,
    SdfCheckboxModule,
    SdfMultiHierarchyAutocompleteModule,
    SdfMultiHierarchyDropdownModule,
    SdfSingleHierarchyAutocompleteModule,
    SdfSingleHierarchyDropdownModule,
    SdfNewToggleModule,
    SdfRichTextModule,SdfNewEditorModule,SdfNewBannerModule,
    MsalModule.forRoot(new PublicClientApplication({
      auth:{
        clientId:'a21bf1ff-6cd2-48d3-886e-87a10dc90bb2',
        authority:'https://login.microsoftonline.com/a33c6ac4-a52e-45c5-af07-b972df9bd004',
        redirectUri:'https://localhost:4202',
      },
      cache:{
        cacheLocation:'localStorage',
        storeAuthStateInCookie:isIE,
      }
    }),null,{
      interactionType:InteractionType.Redirect,
      protectedResourceMap:new Map([
        ['https://graph.microsoft.com/v1.0/me',['api://57a9dced-4bf8-4f82-a533-e0fdad9e5555/.default']]
      ])
    })
  ],
  providers: [{
    provide:HTTP_INTERCEPTORS,
    useClass:MsalInterceptor,
    multi:true
  }],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }


