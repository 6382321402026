import { Component, OnInit, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-sdf-inline-message',
  templateUrl: './sdf-inline-message.component.html',
  styleUrls: ['./sdf-inline-message.component.css']
})
export class SdfInlineMessageComponent implements OnInit {

  constructor(private cdref: ChangeDetectorRef) { }
  hide_opt:boolean = false;
  message:string;
  alertTitle:string;
  toggle_choice:any={
    'type':{
      'Subtle':'inline-Message',
      'Alert':'alert-Message'
    },
    'messageType':{
      'Default':'default',
      'Informative':'information',
      'Positive':'confirmation',
      'Cautionary':'warning',
      'Negative':'error'
    },
    'dismissable':{
      'Yes':'yes',
      'No':'no'
    }
  }

  toggle_o_selected:any={
    'type':'inline-Message',
    'messageType':'default',
    'width':'auto',
    'inlineData':'Default',
    'dismissable':'no'
  }
  ngOnInit(): void {
  }
  i_repeat:number=0;hide_component:boolean=true;
  toggle_selected(val,v){
    //this.i_repeat++;
    //if(this.i_repeat>4){
      this.toggle_o_selected[v]=this.toggle_choice[v][val];
      if(this.toggle_o_selected.type=='inline-Message'){
        if(this.toggle_o_selected.messageType=='default'){
          this.message='We recommend minimum 8 character for strong passwords'
        }else if(this.toggle_o_selected.messageType=='information'){
          this.message='Your profile has been updated'
        }else if(this.toggle_o_selected.messageType=='confirmation'){
          this.message='You have successfully submitted the application'
        }else if(this.toggle_o_selected.messageType=='warning'){
          this.message='You have reached maximum limit.Please Upgrade to premium.'
        }else if(this.toggle_o_selected.messageType=='error'){
          this.message='There was a problem sending your mail.Please try again.'
        }
      }else if(this.toggle_o_selected.type=='alert-Message'){
        if(this.toggle_o_selected.messageType=='default'){
          this.alertTitle='IKEA recommend';
          this.message='We recommend minimum 8 character for strong passwords'
        }else if(this.toggle_o_selected.messageType=='information'){
          this.alertTitle='Information';
          this.message='Your profile has been updated'
        }else if(this.toggle_o_selected.messageType=='confirmation'){
          this.alertTitle='Congratutions';
          this.message='You have successfully submitted the application'
        }else if(this.toggle_o_selected.messageType=='warning'){
          this.alertTitle='Heads up';
          this.message='You have reached maximum limit.Please Upgrade to premium.'
        }else if(this.toggle_o_selected.messageType=='error'){
          this.alertTitle='Opps';
          this.message='There was a problem sending your mail.Please try again.'
        }
      }
      console.log(this.toggle_o_selected);
      this.hide_component=false;
      setTimeout(()=>{
        this.hide_component=true;
      },2)
    //}
    
  }

  clicked(){
    console.log('button clicked')
  }
  ngAfterContentChecked() {

    this.cdref.detectChanges();

  }
}
