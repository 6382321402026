// this component contains the methods and properties related to login
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AuthenticationService } from 'src/core/services/authentication.service';
import { BehaviorSubject } from 'rxjs';
import { User } from '@sdf/sdf-ui/lib/core/models';
import * as CryptoJS from 'crypto-js'; 
import { LoginService } from './login.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  userName: string = '';
  password: string = '';
  selectedSupplier: string = '';
  appForm: FormGroup;
  isError:boolean=false;
  secretKey = "IkeaPasswordIkeaPassword";

  @ViewChild('login_button', {static:false}) login_button:ElementRef;

  private userDetails: BehaviorSubject<User>;
  constructor(
    private router: Router,private authenticationService: AuthenticationService,private _AESEncryptDecryptService: LoginService
  ) {
  }

  ngOnInit() {
  
    //Ensures to clear the session variables of the the user.
    if(this.get_cookie('sid')){
      window.location.href='/#/home';
    }

  }

  ngBeforeViewInit(){
    
  }

  get_cookie(cn) {
    let n = cn + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    //console.log(ca)
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i].split('=');
      if(c[0].indexOf(cn)>-1){
        return c[1];
      }
    }
    return "";
  }

  key_down(event:any){
    if(event.key=='Enter'){
      //this.login_button.nativeElement.button_clicked()
      this.onLogin();
    }
  }

  onChangetext(event) {
    //alert(event);
  }

  onChangeSelect(event) {
    this.selectedSupplier = event.objectId;
  }
  onLogin() {//event
    //console.log(this.userName,this.password)
    
    //Redirect to home Page
    // var data = this._AESEncryptDecryptService.encrypt(this.password)
    // console.log("conversionEncryptOutput",data)
    // let decryptedText = this._AESEncryptDecryptService.decrypt(data);
    // console.log("decryptedText",decryptedText)
    var encrypted = this._AESEncryptDecryptService.set(this.secretKey, this.password);
    var decrypted = this._AESEncryptDecryptService.get(this.secretKey, encrypted);
   
   // console.log('Encrypted :' + encrypted);
    //console.log('decryptedText :' + decrypted);
    let arg={
      'username':this.userName,
      'password':encrypted
    }



    this.authenticationService.saveUser(arg).subscribe(res => {
      if(res.result){
        console.log(res)
        document.cookie ='sid='+res.sessionId;
        localStorage.setItem('user', JSON.stringify(this.userName));
        this.authenticationService.userId = res.result;
        this.authenticationService.userData = JSON.parse(localStorage.getItem('user'));
        this.authenticationService.sendUserDetails(JSON.parse(localStorage.getItem('user')))
        this.router.navigateByUrl("/home");
      }
    }, error => {
      console.log("error",error);
      this.isError =true;
    })
    
  }
  loadSupplierList() {
    // //This function to get the suppliers of the respective user.
    // this.connectIamService.getObjects('SUP', this.userName, 'checkin_document', '')
    //   .subscribe
    //   (connectIamCol => { this.connectIamCol = connectIamCol; })
  }
  oncancel() {//event
    this.userName = "";
    this.password = "";
    
  }
  ngOnDestroy() {}
}
