import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sdf-table',
  templateUrl: './sdf-table.component.html',
  styleUrls: ['./sdf-table.component.scss']
})
export class SdfTableComponent implements OnInit {

  constructor() { }
  hide_opt:boolean = false;
  isNotEditted: boolean = true;
  toggle_choice:any={
    'height':{
      '24px':'small',
      '32px':'medium',
      '40px':'large',
      '62px':'xlarge'
    },
    'style':{
      'Lines':'lines',
      'Striped':'stripped',
      'Clean':'plain'
    },
    'filter':{
      'Yes':'yes',
      'No':'no'
    },
    'select':{
      'None':'none',
      'Single':'single',
      'Multiple':'multiple'
    },
    'sort':{
      'No':'no',
      'Yes':'yes'
    },
    'edit':{
      'None':'none',
      'Single':'single',
      'Bulk':'multiple'
    },
    'thumbnail':{
      'Square':'square',
      'Circular':'circular',
      'None':'none'
    },
    'header':{
      'Single':'single',
      'Group':'group'
    },
    'responsive':{
      'No':'no',
      'Freeze Column':'freeze_column',
      'Table View':'table_view'
    },
    'multipleSelection':{
      'On checkbox':'checkbox',
      'Anywhere on row':'drag'
    },
    'header-type':{
      'SDF':'sdf-grid',
      'Skapa':'skapa-grid'
    }
  }
  toggle_o_selected:any={
    'type':'regular',
    'height':'medium',
    'style':'line',
    'select':'single',
    'filter':'no',
    'sort':'no',
    'edit':'none',
    'thumbnail':'none',
    'header':'single',
    'responsive':'no',
    'header-type':'sdf-grid'
  }

  public selectedKeys: string[];
  public data=[
    {CustomerID:'ALFKI',CompanyName:'ABCD',ContactName:'Maria Anders',Date:'2020-06-23'},
    {CustomerID:'ANATR',CompanyName:'IKEA',ContactName:'Ana Trujillo',Date:'2020-06-23'},
    {CustomerID:'ANTON',CompanyName:'EFGH',ContactName:'Antonio Moreno',Date:'2020-06-23'},
    {CustomerID:'CACTU',CompanyName:'XYZ',ContactName:'Comidas para Anders',Date:'2020-06-23'},
    {CustomerID:'ALFKI',CompanyName:'IKEA',ContactName:'Maria Anders',Date:'2020-06-23'},
    {CustomerID:'CACTU',CompanyName:'PQRS',ContactName:'Cactus Comidas para',Date:'2020-06-23'},
    {CustomerID:'ALFKI',CompanyName:'IKEA',ContactName:'Maria Anders',Date:'2020-06-23'},
    {CustomerID:'CACTU',CompanyName:'IKEA',ContactName:'Cactus Comidas para',Date:'2020-06-23'},
    {CustomerID:'ANATR',CompanyName:'IKEA',ContactName:'Ana Trujillo',Date:'2020-06-23'},
    {CustomerID:'ANTON',CompanyName:'EFGH',ContactName:'Antonio Moreno',Date:'2020-06-23'},
    {CustomerID:'CACTU',CompanyName:'XYZ',ContactName:'Comidas para Anders',Date:'2020-06-23'},
    {CustomerID:'ALFKI',CompanyName:'IKEA',ContactName:'Maria Anders',Date:'2020-06-23'},
    {CustomerID:'CACTU',CompanyName:'PQRS',ContactName:'Cactus Comidas para',Date:'2020-06-23'},
    {CustomerID:'ALFKI',CompanyName:'IKEA',ContactName:'Maria Anders',Date:'2020-06-23'},
    {CustomerID:'qwert',CompanyName:'IKEA',ContactName:'Cactus Comidas para',Date:'2020-06-23'},
    {CustomerID:'asdfg',CompanyName:'ABCD',ContactName:'Maria Anders',Date:'2020-06-23'},
    {CustomerID:'qwert',CompanyName:'IKEA',ContactName:'Ana Trujillo',Date:'2020-06-23'},
    {CustomerID:'ANTON',CompanyName:'EFGH',ContactName:'Antonio Moreno',Date:'2020-06-23'},
    {CustomerID:'CACTU',CompanyName:'XYZ',ContactName:'Comidas para Anders',Date:'2020-06-23'},
    {CustomerID:'ALFKI',CompanyName:'IKEA',ContactName:'Maria Anders',Date:'2020-06-23'},
    {CustomerID:'CACTU',CompanyName:'PQRS',ContactName:'Cactus Comidas para',Date:'2020-06-23'},
    {CustomerID:'ALFKI',CompanyName:'IKEA',ContactName:'Maria Anders',Date:'2020-06-23'},
    {CustomerID:'CACTU',CompanyName:'IKEA',ContactName:'Cactus Comidas para',Date:'2020-06-23'},
    {CustomerID:'ANATR',CompanyName:'IKEA',ContactName:'Ana Trujillo',Date:'2020-06-23'},
    {CustomerID:'ANTON',CompanyName:'EFGH',ContactName:'Antonio Moreno',Date:'2020-06-23'},
    {CustomerID:'CACTU',CompanyName:'XYZ',ContactName:'Comidas para Anders',Date:'2020-06-23'},
    {CustomerID:'ALFKI',CompanyName:'IKEA',ContactName:'Maria Anders',Date:'2020-06-23'},
    {CustomerID:'CACTU',CompanyName:'PQRS',ContactName:'Cactus Comidas para',Date:'2020-06-23'},
    {CustomerID:'ALFKI',CompanyName:'IKEA',ContactName:'Maria Anders',Date:'2020-06-23'},
    {CustomerID:'CACTU',CompanyName:'IKEA',ContactName:'Cactus Comidas para',Date:'2020-06-23'}
  ]

  darta:any=[
    {CustomerID:'12345',CompanyName:'ABCD',ContactName:'Maria Anders',Date:'2020-06-23'},
    {CustomerID:'33333',CompanyName:'IKEA',ContactName:'Ana Trujillo',Date:'2020-06-23'},
    {CustomerID:'ANTON',CompanyName:'EFGH',ContactName:'Antonio Moreno',Date:'2020-06-23'},
    {CustomerID:'5566788',CompanyName:'XYZ',ContactName:'Comidas para Anders',Date:'2020-06-23'},
    {CustomerID:'ALFKI',CompanyName:'IKEA',ContactName:'Maria Anders',Date:'2020-06-23'},
    {CustomerID:'54333',CompanyName:'PQRS',ContactName:'Cactus Comidas para',Date:'2020-06-23'},
    {CustomerID:'ALFKI',CompanyName:'IKEA',ContactName:'Maria Anders',Date:'2020-06-23'},
    {CustomerID:'34545',CompanyName:'IKEA',ContactName:'Cactus Comidas para',Date:'2020-06-23'},
    {CustomerID:'ANATR',CompanyName:'IKEA',ContactName:'Ana Trujillo',Date:'2020-06-23'},
    {CustomerID:'34555',CompanyName:'EFGH',ContactName:'Antonio Moreno',Date:'2020-06-23'},
    {CustomerID:'CACTU',CompanyName:'XYZ',ContactName:'Comidas para Anders',Date:'2020-06-23'},
    {CustomerID:'AL33334FKI',CompanyName:'IKEA',ContactName:'Maria Anders',Date:'2020-06-23'},
    {CustomerID:'asasasas',CompanyName:'PQRS',ContactName:'Cactus Comidas para',Date:'2020-06-23'},
  ]
  scrollData:any;

  ngOnInit(): void {
  }
  i_repeat:number=0;hide_component:boolean=true;editable:boolean=false;
  disable_edit:boolean=false;disable_select:boolean=false;
  edit_sv:string='None';select_sv:string='Single';
  toggle_selected(val,v){
    this.i_repeat++;

    /* if code style changes from striped then make default edit/select value */
    

    if(v=='style' && val=='Striped'){ 
      this.disable_edit=true;
      this.disable_select=true;
      
    }else{
      this.disable_edit=false;
      this.disable_select=false;
    }

    if(this.i_repeat>7){
      this.toggle_o_selected[v]=this.toggle_choice[v][val];

      if(this.toggle_o_selected['edit']!='none')
        this.editable=true;

      console.log(this.toggle_o_selected);
      this.hide_component=false;
      setTimeout(()=>{
        this.hide_component=true;
      },0)
    }
    console.log(this.disable_edit,this.edit_sv)
  }
  nextpage(val:any){
    console.log(val);
    if(val.page==1){
      this.data = [...this.data,...this.darta];
    }
  }
  scrolldown(val:any){
    console.log(val)
  }

  outputEvent(data){
    console.log("output",data)
    if(data.type){
      // if(data.value && data.value.length > 0){
      //   alert('all unsaved data will be deleted');
      // }
    }
    else{
      if(data.length > 0){
        this.isNotEditted = false;
      }
      else
      this.isNotEditted = true;
    }
  }
  cancelClick(data){
    this.isNotEditted = true;
  }
  saveClick(data){
    console.log(data);
    this.isNotEditted=true;
  }
  outputEventItem(e){
    console.log("items",e)
  }
}
