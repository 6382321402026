import { ChangeDetectorRef, Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sdf-textbox',
  templateUrl: './sdf-textbox.component.html',
  styleUrls: ['./sdf-textbox.component.css']
})
export class SdfTextboxComponent implements OnInit {

  constructor(private cdref: ChangeDetectorRef) { }

  hide_opt:boolean = false;hide_code:boolean=true;
  toggle_choice:any={
    'size':{
      '32px':'small',
      '40px':'medium',
      '48px':'large'
    },
    'type':{
      'Short text':'regular',
      'Password':'password',
      'Long text':'textarea'
    },
    'label':{
      'On Frame':'frame',
      'Outside':'outside'
    }
  }

  toggle_o_selected:any={
    'size':'medium',
    'type':'regular',
    'label':'frame'
  }

  sampletext:string="Sample Text";
  ngOnInit(): void {
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  i_repeat:number=0;hide_component:boolean=true;txt_msg:string='Enter Name';
  toggle_selected(val,v){

    this.i_repeat++;
    if(this.i_repeat>3){
      this.toggle_o_selected[v]=this.toggle_choice[v][val];
      //console.log(this.toggle_o_selected);

      this.hide_component=false;
      setTimeout(()=>{
        this.hide_component=true;
      },2)
    }

    this.txt_msg = this.toggle_o_selected['type']=='regular'?'Enter Nmae':(this.toggle_o_selected['type']=='password'?'Enter Password':'Enter Text');
    
  }

  onChange(event) {
    console.log(event);
  }

  onKeypress(event) {
    console.log(event);
  }

  onFocus(event) {
    console.log(event);
  }

  onBlur(event) {
    console.log(event);
  }

  copy_code(eli:any){
    eli = 'rtyur';//this.toggle_o_selected['thumbnail']=='none'?'rtyu':'rtyur';
    let el = document.getElementById(eli) as HTMLElement;
    let da = el.innerHTML.replace(/&lt;/g,'<');
    da = da.replace(/&gt;/g,'>')
    navigator.clipboard.writeText(da);
    console.log(da)
  }

}
