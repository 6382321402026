import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sdf-pills-sample',
  templateUrl: './sdf-pills-sample.component.html',
  styleUrls: ['./sdf-pills-sample.component.scss']
})
export class SdfPillsSampleComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  outputEvent(data){
    console.log(data);
  }

}
