import { Component, OnInit } from '@angular/core';
import { HttpClient,HttpParams,HttpHeaders  } from  '@angular/common/http';

@Component({
  selector: 'app-sdf-switch-sample',
  templateUrl: './sdf-switch-sample.component.html',
  styleUrls: ['./sdf-switch-sample.component.scss']
})
export class SdfSwitchSampleComponent implements OnInit {
  disabled = false;
  checked = true;
  constructor(private http:HttpClient) { }

  data:any=[];data1:any;
  ngOnInit() {

    this.http.get('https://networkingtesting.azurewebsites.net/api/BlobStorage/GetBlobs').subscribe((res)=>{
      console.log(res);
      this.data=res;
    },
    (error)=>{
      console.log(error)
    })

    this.http.get('https://azureactivedirectorytesting.azurewebsites.net/api/BlobStorage/GetAllBlobs').subscribe((res)=>{
      //console.log(res);
      this.data1=res;
    },
    (error)=>{
      console.log(error)
    })

  }

  ext_data(d:string):string{
    let p = d.indexOf('https');
    let s = d.substr(p);
    //console.log(s)
    return s;
  }

  click(data){
    console.log(data);
  }

}
