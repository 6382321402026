import { HttpClient } from "@angular/common/http";
import { GridDataResult } from "@progress/kendo-angular-grid";
//import { toODataString } from "@progress/kendo-data-query";
import { Observable, BehaviorSubject } from "rxjs";
import { map, tap } from "rxjs/operators";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class ODataSearchService extends BehaviorSubject<GridDataResult> {
  public loading: boolean;
  public oDataUrl: string;
  
  constructor(private http: HttpClient) {
    super(null);
  }
  public query(preFilter: string, state: any): void {
  }

  

  public getResult(url): Observable<any> {
    return this.http.get(url);
  }

  public connectItems(data): Observable<any> {
    return this.http.post(
      `https://testpte.connect.adfs.enh.ikeadt.com/api/connect/product-structure/v1/structure-connections`,
      data
    );
  }
  public getCountires(): Observable<any> {
    return this.http.get('https://testpte.sdfauthentication.ikeadt.com/iam/info/GetCountries');
  }
  public getGridData(): Observable<any> {
    return this.http.get('http://dummy.restapiexample.com/api/v1/employees');
  }
  public getSelectData(): Observable<any> {
    return this.http.get('https://itemsearch-api-sandbox-dpop-prm.ocp-01.ikeadt.com/pia-item-search/prod-name?langCode=GB&prodNamesearch=%25');
  }
  public loadMoreData(skip: number,take: number): Observable<any> {
        const queryStr = `${toODataString({ skip, take })}`;
        console.log("f",queryStr)
        return this.http.get(`https://odatasampleservices.azurewebsites.net/V4/Northwind/Northwind.svc/Orders?${queryStr}`);
        // const skip = this.skip;
        // const queryStr = `${toODataString({ skip, take })}`;
        // console.log("take",queryStr)
       
        // const progress = this.http
        //     .get(`https://odatasampleservices.azurewebsites.net/V4/Northwind/Northwind.svc/Orders?${queryStr}`)
        //     .pipe(
        //         map(response => response['value']),
        //         tap(values => {
        //             if (values.length > 0) {
        //               this.data = [...this.data, ...values];
        //               this.skip += values.length;
        //             }
        //             console.log("data",this.data) 
        //         })
        //     );
        //     console.log("progress",progress
        //     )      
        // return progress;
  }
}
function toODataString(arg0: { skip: number; take: number; }) {
  throw new Error("Function not implemented.");
}

