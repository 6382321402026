import { Component, CUSTOM_ELEMENTS_SCHEMA, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AuthenticationService } from 'src/core/services/authentication.service';
import { HomeService } from './home.service';
import { DomSanitizer } from '@angular/platform-browser';
import { SdfAlertsService } from '@sdf/sdf-ui'; 
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  @ViewChild('script-tag', { read: ElementRef }) scriptMarkerTag: ElementRef;
  widgetJsCode: string = null;
  showNotification = false;
  newNotification: boolean = false;
  userData: any = '';
  isPopup= false;
  isLoading=true;

  constructor(private authenticationService: AuthenticationService, private sdfAlertService: SdfAlertsService, private homeService: HomeService,private _sanitizer: DomSanitizer) {

  }
  ngOnInit() {
    // this.widgetJsCode = this.generateJsCode();s
    //console.log('testing', this.authenticationService.userData);
    this.userData = this.authenticationService.userData;
    let data = {
      'appCode': 'SDF',//'ToCompete',
      'userId': this.userData
    }
    this.homeService.checkNotification(data).subscribe(res => {
      let flag: any = res;
      this.newNotification = flag;
    });
    this.homeService.getPopupNotification(data).subscribe(res => {
      if(res){
        //this.showNotificationAlert()
        this.isPopup = true;
      }
    });
  }

  ngAfterViewInit() {
    // this.init();
  }

  closePopup() {
    //this.showNotification = false;
    this.isPopup = false;
    this.closeNotificationPopup();
  }

  clickWidget() {
    // document.getElementById('navbar-widget-icon').click();
    if(!this.showNotification){
    this.showNotification = true;
    this.userData = JSON.parse(localStorage.getItem('user'));
    let data = {
      'appCode': 'SDF',//'ToCompete',
      'userId': this.userData
    }
    this.homeService.updateNotification(data).subscribe(res => {
    });
    this.newNotification = false;
  }
  else{
    this.showNotification = false;
    // this.newNotification = true;
  }
  }
  
  iframeURLsafe(){
    let type="widget";
    //let url = "https://notificationwidget.azurewebsites.net/?appCode=ToCompete&appSection=*&userId=" + this.userData + "&type="+ type;
    let url = environment.nonprod_widget+"/?appCode=SDF&userId=" + this.userData + "&type="+ type;
    
    return this._sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  private loadonce:boolean = false;

  private loadone:boolean = false;
  loadpopupiFrame(){
    if(this.loadone)return;this.loadone = true;
    let type="popup";
    //let url = "https://notificationwidget.azurewebsites.net/?appCode=ToCompete&appSection=*&userId=" + this.userData + "&type="+ type;
    let url = environment.nonprod_widget+"/?appCode=SDF&userId=" + this.userData + "&type="+ type;
    
    return this._sanitizer.bypassSecurityTrustResourceUrl(url);
  }


  showNotificationAlert(){
    let type="popup";
    if(this.loadonce)return;
    console.log(this.loadonce)
    let alertData = {
      //"src": "https://notificationwidget.azurewebsites.net/?appCode=ToCompete&appSection=*&userId=" + this.userData+ "&type="+ type,
      "src": environment.nonprod_widget+"/?appCode=SDF&appSection=*&userId=" + this.userData+ "&type="+ type,
      
    }
    // this.sdfAlertService.confirmNotificationThis(alertData, () =>{
    //   console.log('Confirm');
    // } , function () {  
    //   console.log('Cancel');
    //   this.userData = this.authenticationService.userData;
    //   let data = {
    //     'appCode': 'ToCompete',
    //     'userId': this.userData
    //   }
    //   this.homeService.closePopupNotification(data).subscribe(res => {
    //     console.log(res)
    //   });
    // })
    //return;
    this.loadonce=true;
    this.sdfAlertService.confirmNotificationThis(alertData, () =>{
            console.log('Confirm');
          } , 
      () =>{  
            console.log('Cancel');
            this.userData = this.authenticationService.userData;
            let data = {
              'appCode': 'SDF',//'ToCompete',
              'userId': this.userData
            }
            this.homeService.closePopupNotification(data).subscribe(res => {
              console.log(res)
            });
          }) 
        
  }
  closeNotificationPopup(){
    let data = {
      'appCode':'SDF' ,//'ToCompete',
      'userId': this.userData
    }
    this.homeService.closePopupNotification(data).subscribe(res => {
      //console.log(res)
    });
  }

  // to redirect user to ikea mail
  public help_user():void{
    location.href="mailto:jira.AESD.se@inter.ikea.com";
  }

}
