import { Component, OnInit, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-sdf-checkbox-sample',
  templateUrl: './sdf-checkbox-sample.component.html',
  styleUrls: ['./sdf-checkbox-sample.component.css']
})
export class SdfCheckboxSampleComponent implements OnInit {
  flowListItems: any;
  constructor() { }

  
  ngOnInit(): void {
    this.flowListItems = [

      {
        value: 'a',
        text: 'Label',
        disabled: false,
        checked: false
      },/*
      {
        value: 'b',
        text: 'Label',
        disabled: true,
        checked: true
      },
      {
        value: 'c',
        text: 'Label',
        disabled: true,
        checked: false
      },
      {
        value: 'd',
        text: 'Label',
        disabled: false,
        checked: true
      }*/
    ];
  }

  onChange(event) {
    console.log(event)
  }

  clicked(){
    console.log('olll');

  }
  working(v){
    console.log(v);
  }
  label_clicked(e){
    console.log(e+' label');
  }
  checkbox_clicked(e){
    console.log(e+' chekcbox');
  }

}
