import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sdf-switch',
  templateUrl: './sdf-switch.component.html',
  styleUrls: ['./sdf-switch.component.css']
})
export class SdfSwitchComponent implements OnInit {

  disabled = false;
  checked = true;
  constructor() { }

  ngOnInit() {
  }

  click(data){
    console.log(data);
  }

}
