import { ChangeDetectorRef, Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sdf-checkbox',
  templateUrl: './sdf-checkbox.component.html',
  styleUrls: ['./sdf-checkbox.component.css']
})
export class SdfCheckboxComponent implements OnInit {

  constructor(private cdref: ChangeDetectorRef) { }

  hide_opt:boolean = false;hide_code:boolean=true;
  toggle_choice:any={
    'size':{
      '16px':'S',
      '24px':'L'
    },
    'appearance':{
      'Subtle':'Subtle',
      'Emphasised':'Emphasised'
    },
    'state':{
      'Unselected':'Unselected',
      'Selected':'Selected',
      'Indeterminate':'Indeterminate'
    },
    'error':{
      'Yes':'yes',
      'No':'no'
    },
    'disable':{
      'Yes':'yes',
      'No':'no'
    },
  }

  toggle_o_selected:any={
    'size':'S',
    'appearance':'Subtle',
    'error':'no',
    'state':'Unselected',
    'disable':'no',
  }

  ngOnInit(): void {
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  i_repeat:number=0;hide_component:boolean=true;update_disable:boolean=true;
  toggle_selected(val,v){

    // if((v=='error' && val=='Yes') || this.toggle_o_selected['error']=='yes'){
    //   this.toggle_o_selected['disable']='no';
    //   this.toggle_o_selected['state']='Unselected';
    // }
    
    this.i_repeat++;
    if(this.i_repeat>3){
      this.toggle_o_selected[v]=this.toggle_choice[v][val];
      console.log(this.toggle_o_selected);
      this.hide_component=false;this.update_disable=false;
      setTimeout(()=>{
        this.hide_component=true;this.update_disable=true;
      },2)
    }
    
  }

  checkbox_clicked(event){
    console.log('checkbhox clicked ',event);
  }
  label_clicked(event){
    console.log('label clicked')
  }
  copy_code(eli:any){
    eli = 'rtyur';//this.toggle_o_selected['thumbnail']=='none'?'rtyu':'rtyur';
    let el = document.getElementById(eli) as HTMLElement;
    let da = el.innerHTML.replace(/&lt;/g,'<');
    da = da.replace(/&gt;/g,'>')
    navigator.clipboard.writeText(da);
    console.log(da)
  }
}
