import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sdf-toggle-sample',
  templateUrl: './sdf-toggle-sample.component.html',
  styleUrls: ['./sdf-toggle-sample.component.scss']
})
export class SdfToggleSampleComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
