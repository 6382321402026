import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sdf-button-sample',
  templateUrl: './sdf-button-sample.component.html',
  styleUrls: ['./sdf-button-sample.component.css']
})
export class SdfButtonSampleComponent implements OnInit {
  constructor() {}

  items: any = [
    { title: 'PRIMARY BUTTON ', buttonType: 'primary', icon: '',height:'normal' },
    { title: 'SECONDARY BUTTON ', buttonType: 'secondary', icon: '',height:'medium'  },
    { title: 'TEXT BUTTON ', buttonType: 'text', icon: '',height:'short'  },
    { title: 'TEXT WITH ICON BUTTON', buttonType: 'text', icon: 'calendar',height:'normal' },
    { title: 'ICON ONLY BUTTON', buttonType: 'icon', icon: 'calendar',height:'normal'  },
    { title: 'PRIMARY BUTTON', buttonType: 'primary',disabled:'true', icon: '',height:'normal'  },
    { title: 'SECONDARY BUTTON', buttonType: 'secondary',disabled:'true', icon: '',height:'normal'  },
    { title: 'TEXT BUTTON', buttonType: 'text',disabled:'true', icon: '' ,height:'normal' },
    { title: 'ICON ONLY BUTTON', buttonType: 'icon',disabled:'true', icon: 'calendar',height:'normal'  }
  ];

  public staticData =[
    {CompanyName:'ALFKI',ContactName:'Maria Anders', Button1:{icon:'fas fa-calendar',disabled:false},Button2:{icon:'fas fa-edit',disabled:false}},
    {CompanyName:'ANATR',ContactName:'Ana Trujillo',Button1:{icon:'fas fa-play',disabled:false},Button2:{icon:'fas fa-save',disabled:false}},
    {CompanyName:'ANTON',ContactName:'Antonio Moreno',Button1:{icon:'fas fa-eye',disabled:false},Button2:{icon:'fas fa-edit',disabled:false}},
    {CompanyName:'CACTU',ContactName:'Comidas para Anders', Button1:{icon:'fas fa-calendar',disabled:true},Button2:{icon:'fas fa-save',disabled:true}},
    {CompanyName:'LAXSS',ContactName:'Mary Sanders', Button1:{icon:'fas fa-play',disabled:true},Button2:{icon:'fas fa-edit',disabled:true}},
    {CompanyName:'MAFRS',ContactName:'Cactus Comidas para', Button1:{icon:'fas fa-eye',disabled:true},Button2:{icon:'fas fa-save',disabled:true}},
    {CompanyName:'QEETW',ContactName:'Tesla Anders', Button1:{icon:'fas fa-calendar',disabled:false},Button2:{icon:'fas fa-edit',disabled:false}},
    {CompanyName:'PLLOO',ContactName:'Comidas para Anders', Button1:{icon:'fas fa-calendar',disabled:false},Button2:{icon:'fas fa-save',disabled:false}},
    {CompanyName:'YUIO',ContactName:'Maria Anders', Button1:{icon:'fas fa-calendar',disabled:false},Button2:{icon:'fas fa-edit',disabled:false}},
    {CompanyName:'VBMH',ContactName:'Cactus Comidas para', Button1:{icon:'fas fa-calendar',disabled:true},Button2:{icon:'fas fa-play',disabled:true}},
    {CompanyName:'MAFRS',ContactName:'Cactus Comidas para', Button1:{icon:'fas fa-calendar',disabled:false},Button2:{icon:'fas fa-save',disabled:false}},
    {CompanyName:'QEETW',ContactName:'Tesla Anders', Button1:{icon:'fas fa-calendar',disabled:false},Button2:{icon:'fas fa-edit',disabled:false}},
    {CompanyName:'PLLOO',ContactName:'Comidas para Anders', Button1:{icon:'fas fa-play',disabled:false},Button2:{icon:'fas fa-calendar',disabled:false}},
    {CompanyName:'YUIO',ContactName:'Maria Anders', Button1:{icon:'fas fa-calendar',disabled:false},Button2:{icon:'fas fa-save',disabled:false}},
    {CompanyName:'VBMH',ContactName:'Cactus Comidas para', Button1:{icon:'fas fa-calendar',disabled:true},Button2:{icon:'fas fa-edit',disabled:true}},
    {CompanyName:'IORE',ContactName:'Maria Anders', Button1:{icon:'fas fa-calendar',disabled:false},Button2:{icon:'fas fa-edit',disabled:false}},
    {CompanyName:'BNB',ContactName:'Cactus Comidas para', Button1:{icon:'fas fa-calendar',disabled:false},Button2:{icon:'fas fa-save',disabled:false}},
  ]

  // gridColumns=[
  //   { field: "CompanyName", title: "CompanyName" },
  //   { field: "Button1", title: "Button1", template:bt1},
  //   {field:"Button2", title:"Button2", template:bt2}
  //   ];

  ngOnInit() {}

  loginClick(event) {
    console.log(event);
  }
  check_me(){
    console.log('dd')
  }
}
