import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { User } from '@sdf/sdf-ui/lib/core/models';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private userSubject: BehaviorSubject<User>;
  private userDetails= new Subject<any>();
  public userData: any;
  public userId:any;

    public user: Observable<User>;
    loginData = this.userDetails.asObservable();

    constructor(
        private router: Router,
        private http: HttpClient
    ) {
        this.userSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('user')));
        this.user = this.userSubject.asObservable();
       
    }

    public get userValue(): User {
        return this.userSubject.value;
    }

    login(username) {
      this.userDetails.next({"userId":username});
      let user ={"userId":username}
      return user;
     
        // return this.http.post<User>(`${environment.apiUrl}/ValidateUserType?`, { username, password })
        //     .pipe(map(user => {
        //         // store user details and jwt token in local storage to keep user logged in between page refreshes
        //         localStorage.setItem('user', JSON.stringify(user));
        //         this.userSubject.next(user);
        //         return user;
        //     }));
    }

    saveUser(data):Observable<any>{
      return this.http.post(`${environment.apiUrl}/ValidateUserType`, data);
    }

    session_login(data):Observable<any>{
      return this.http.post(`${environment.apiUrl}/ValidateUserType`,data)
    }


    logout() {
        // remove user from local storage and set current user to null
        localStorage.removeItem('user');
        this.userData = 0;
        this.userSubject.next(null);
        document.cookie='sid=0; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        this.router.navigate(['/login']);
    }

    sendUserDetails(message: string) {
      this.userDetails.next({ text: message });
    }
  
    getUserDetails(): Observable<any> {
        return this.userDetails.asObservable();
    }


}
