import { Component, OnInit } from '@angular/core';
import { ODataSearchService } from "../../../core/services/o-data-search.service";

@Component({
  selector: 'app-sdf-table-single-selection-sample',
  templateUrl: './sdf-table-single-selection-sample.component.html',
  styleUrls: ['./sdf-table-single-selection-sample.component.css']
})
export class SdfTableSingleSelectionSampleComponent implements OnInit {

  loading: boolean = false;
  public value: Date = new Date();
  serviceData=[];
  scrollData=[];
  backupData=[]
  skip=0;
  pagesize=5;
  reset = false;
  recordCount= 355;
  index;
  isNotEditted: boolean = true;
constructor(private service: ODataSearchService){}
  ngOnInit() {
    this.service.getGridData().subscribe(response =>{
      this.serviceData = response.data;
      

    })
    this.service.loadMoreData(this.skip,this.pagesize).subscribe(response =>{
     this.scrollData=response.value
    //  this.backupData = this.scrollData;
    })
    this.index=1;
   }
   
  public selectedKeys: string[];
  public data=[
    {CustomerID:'ALFKI',CompanyName:'ABCD',ContactName:'Maria Anders',Date:'2020-06-23'},
    {CustomerID:'ANATR',CompanyName:'IKEA',ContactName:'Ana Trujillo',Date:'2020-06-23'},
    {CustomerID:'ANTON',CompanyName:'EFGH',ContactName:'Antonio Moreno',Date:'2020-06-23'},
    {CustomerID:'CACTU',CompanyName:'XYZ',ContactName:'Comidas para Anders',Date:'2020-06-23'},
    {CustomerID:'ALFKI',CompanyName:'IKEA',ContactName:'Maria Anders',Date:'2020-06-23'},
    {CustomerID:'CACTU',CompanyName:'PQRS',ContactName:'Cactus Comidas para',Date:'2020-06-23'},
    {CustomerID:'ALFKI',CompanyName:'IKEA',ContactName:'Maria Anders',Date:'2020-06-23'},
    {CustomerID:'CACTU',CompanyName:'IKEA',ContactName:'Cactus Comidas para',Date:'2020-06-23'},
    {CustomerID:'ANATR',CompanyName:'IKEA',ContactName:'Ana Trujillo',Date:'2020-06-23'},
    {CustomerID:'ANTON',CompanyName:'EFGH',ContactName:'Antonio Moreno',Date:'2020-06-23'},
    {CustomerID:'CACTU',CompanyName:'XYZ',ContactName:'Comidas para Anders',Date:'2020-06-23'},
    {CustomerID:'ALFKI',CompanyName:'IKEA',ContactName:'Maria Anders',Date:'2020-06-23'},
    {CustomerID:'CACTU',CompanyName:'PQRS',ContactName:'Cactus Comidas para',Date:'2020-06-23'},
    {CustomerID:'ALFKI',CompanyName:'IKEA',ContactName:'Maria Anders',Date:'2020-06-23'},
    {CustomerID:'CACTU',CompanyName:'IKEA',ContactName:'Cactus Comidas para',Date:'2020-06-23'}
  ]

  public newdata=[
    {CustomerID:'ALFKI',ShipCity:'ABCD',ShipCountry:'Maria Anders',Date:'2020-06-23'},
    {CustomerID:'ANATR',ShipCity:'IKEA',ShipCountry:'Ana Trujillo',Date:'2020-06-23'},
    {CustomerID:'ANTON',ShipCity:'EFGH',ShipCountry:'Antonio Moreno',Date:'2020-06-23'},
  ]

  outputEvent(data){
    console.log("output",data)
    if(data.type){
      // if(data.value && data.value.length > 0){
      //   alert('all unsaved data will be deleted');
      // }
    }
    else{
      if(data.length > 0){
        this.isNotEditted = false;
      }
      else
      this.isNotEditted = true;
    }
  }

  cancelClick(data){
    this.skip = 0;
    this.pagesize = 5;
    this.service.loadMoreData(this.skip,this.pagesize).subscribe(response =>{
      // this.child.resetGrid();
      if(this.reset){
        this.reset = false;
      }
      else{
        this.reset = true;
      }
      this.scrollData=response.value
      this.isNotEditted = true;
     })
  }

  saveClick(data){
    this.skip = 0;
    this.pagesize = 5;
    this.service.loadMoreData(this.skip,this.pagesize).subscribe(response =>{
      // this.child.resetGrid();
      if(this.reset){
        this.reset = false;
      }
      else{
        this.reset = true;
      }
      this.scrollData=response.value
      this.isNotEditted = true;
     })
     
  }
  scrollEvent(e){
    console.log(e)
    this.service.loadMoreData(this.skip,this.pagesize).subscribe(response =>{
      this.scrollData = [...this.scrollData, ...response.value];
      this.skip += response.value.length;
     })
  }
  ngOnDestroy() {
    if(!this.isNotEditted){
      if(confirm("Do yu want to save the changes ")) {
        console.log("confirmed");
      }
      
    }
  }


}
