import { Component, OnInit } from '@angular/core';

import { SdfAlertsService } from '@sdf/sdf-ui';

@Component({
  selector: 'app-sdf-alert-modal-sample',
  templateUrl: './sdf-alert-modal-sample.component.html',
  styleUrls: ['./sdf-alert-modal-sample.component.css']
})
export class SdfAlertModalSampleComponent implements OnInit {

  constructor(private sdfAlertService: SdfAlertsService) { }

  ngOnInit(): void {
  }

  showAlert(){
    let alertData = {
      "title": "SDF_UI",
      "description": "Commodo consequat amet cillum non ullamco et non et anim velit anim mollit laborum commodo. Culpa incididunt nulla veniam culpa Lorem do voluptate quis. Veniam Lorem officia culpa officia laborum aliquip. Culpa dolor duis amet qui quis sint id esse.",
      "action": {
        "btnPrimary": "Confirm",
        "btnSecondary": "Cancel",
      }
    }
    this.sdfAlertService.confirmThis(alertData, function () {  
      console.log('Confirm');
    }, function () {  
      console.log('Cancel');
    }) 
  }

}
