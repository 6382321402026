import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-sdf-menu-sample',
    templateUrl: './sdf-menu-sample.component.html',
    styleUrls: ['./sdf-menu-sample.component.scss']
})
export class SdfMenuSampleComponent implements OnInit {
    footer = []
    menu = [{
        title: "Menu1",
        children: [{
            title: "Menu1.1",
            children: [
                {
                    title: "Menu1.1.1",
                    route: "route1"
                },
                {
                    title: "Menu1.1.2",
                    route: "route2"
                }
            ],
        },
        {
            title: "Menu1.2",
            children: [
                {
                    title: "Menu1.2.1",
                    route: "route3"
                },
                {
                    title: "Menu1.2.2",
                    route: "route4"
                }
            ]
        }],
    },
    {
        title: "Menu2",
        children: [{
            title: "Menu2.1",
            route: "route5"
        }],
    },
    {
        title: "Menu3",
        route: "route6"
    }];
    constructor() { }

    ngOnInit() {
    }

}
