import { ChangeDetectorRef, Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sdf-pills',
  templateUrl: './sdf-pills.component.html',
  styleUrls: ['./sdf-pills.component.css']
})
export class SdfPillsComponent implements OnInit {

  constructor(private cdref: ChangeDetectorRef) { }

  hide_opt:boolean = false;hide_code:boolean=true;
  toggle_choice:any={
    'type':{
      'Regular':'regular',
      'Icon':'icon',
      'Clarified':'removable',
      'Removable':'delete'
    },
    'height':{
      '40px':'S',
      '56px':'M'
    },
    'width':{
      'Fluid':'fluid',
      'Auto':'auto'
    },
    'state':{
      'Noraml':'normal',
      'Disable':'disable',
      'Selected':'selected'
    },
    'icon_position':{
      'Leading':'left',
      'Trailing':'right'
    },
    'thumbnail':{
      'Square':'square',
      'Circular':'circular',
      'None':'none'
    },
    'thumb_position':{
      'Leading':'left',
      'Trailing':'right'
    }
  }

  toggle_o_selected:any={
    'type':'regular',
    'height':'S',
    'width':'auto',
    'state':'normal',
    'icon_position':'left',
    'thumbnail':'none',
    'thumb_position':'left'
  }

  ngOnInit(): void {
  }
  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  i_repeat:number=0;hide_component:boolean=true;ip_hide:boolean=true;
  thumbnail_type:string='None';icon_positon:string='Leading';
  toggle_selected(val,v){




    // if(v=='type' && (val=='Removable' || val=='Deletable')){
    //   this.icon_positon='Trailing';
    //   this.ip_hide=false;
    //   setTimeout(()=>{
    //     this.ip_hide=true;
    //   },2)
    // }else if(v=='thumbnail'){

    //   if(this.toggle_o_selected['type']=='regular'){

    //   }else{
        
    //   }

    // }

    if(v=='type' && val=='Icon'){
      this.toggle_o_selected['thumbnail']='none';
    }

    this.i_repeat++;
    if(this.i_repeat>3){
      this.toggle_o_selected[v]=this.toggle_choice[v][val];
      console.log(this.toggle_o_selected);
      this.hide_component=false;
      setTimeout(()=>{
        this.hide_component=true;
      },2)
    }
    
  }

  clicked(val:any){
    console.log('button clicked')
  }

  copy_code(eli:any){
    eli = this.toggle_o_selected['thumbnail']=='none'?'rtyu':'rtyur';
    let el = document.getElementById(eli) as HTMLElement;
    let da = el.innerHTML.replace(/&lt;/g,'<');
    da = da.replace(/&gt;/g,'>')
    navigator.clipboard.writeText(da);
    //console.log(da)
  }

}
