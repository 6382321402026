import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import {ODataSearchService} from '../../../core/services/o-data-search.service'
@Component({
  selector: 'app-sdf-dropdown-sample',
  templateUrl: './sdf-dropdown-sample.component.html',
  styleUrls: ['./sdf-dropdown-sample.component.css']
})
export class SdfDropdownSampleComponent implements OnInit {
  appForm: FormGroup;

  constructor(private formBuilder: FormBuilder, private ODataSearchService: ODataSearchService) {
    this.appForm = this.formBuilder.group({
      document: []
    });
  }
  public autoData: Array<string> = [
    'Albania',
    'Andorra',
    'Armenia',
    'Austria',
    'Azerbaijan'
  ];

  listitems: any;
  dropdownitems: any;
  autocompeteitems: any;
  flowListItems: any;
  flowRadioListItems: any;
  flowRadioDisabledItems: any;
  emptyselect: any;
  emptymultipledropselect:any;
  emptyfixedmultipledropselect:any;
  emptyfixedmultipledropselect1:any;
  emptyautocompletedropselect:any;
  emptyautocompletedropselect1:any;
  emptymultipleselect:any;
  emptydisabledmultipleselect:any;
  emptydismultipledropselect:any;
  emptygroup:any;
  selecteddefaultItem: { text: string, value: number } = { text: "Select item...", value: null }
  emptydropselect: any;
  autoCompleteData: any=[];
  public selectedItem: { value: string, text: string } = { value: 'b', text: 'India' };
  public selecteddropItem: { value: string, text: string } = { value: 'b', text: 'India' };
  public disabledselectedItem: { value: string, text: string } = { value: 'b', text: 'India' };
  public disableddropselectedItem: { value: string, text: string } = { value: 'b', text: 'India' };
  public selectedItemmultiple: any = [{
    value: 'c',
    text: 'USA'
  },
  {
    value: 'b',
    text: 'India'
  }];
  public disabledItemmultiple: any = [{value: 'c',text: 'USA'},{value: 'b',text: 'India'}];;
  public selectedItemremote: { CustomerID: string, ShipCountry: string } = { CustomerID: 'VINET', ShipCountry: 'France' };
  public selectedItemmultiple2:any = [{value: 'c',text: 'USA'},{value: 'b',text: 'India'}];


  
  public autolistItems: Array<string> = ["Albania", "Andorra", "Armenia", "Austria", "Australia", "Azerbaijan"];
  public data: Array<string>;
  public dropdata=[]
  defaultItem: { attrName: string, attrNo: string } = { attrName: "Select item...", attrNo: null }
  ngOnInit() {
    // console.log(event, this.appForm.value);
    // console.log(this.selectedItemmultiple2);
    // console.log('automultiple value' + this.selectedItemmultiple);
    this.dropdownitems = [
      { title: 'SMALL SINGLE DROPDOWN', disable: false, height: 'small' },
      { title: 'MEDIUM SINGLE DROPDOWN', disable: false, height: 'medium' },
      { title: 'LARGE SINGLE DROPDOWN', disable: false, height: 'large' },
      {
        title: 'LARGE SINGLE DROPDOWN (DISABLED)',
        disable: true,
        height: 'large'
      }
    ];

    this.dropdata = [
      { tagText: 'SMALL SINGLE DROPDOWN', disable: false, tagNo: 'small' },
      { tagText: 'MEDIUM SINGLE DROPDOWN', disable: false, tagNo: 'medium' },
      { tagText: 'LARGE SINGLE DROPDOWN', disable: false, tagNo: 'large' },
      {
        tagText: 'Tata SINGLE DROPDOWN (DISABLED)',
        disable: true,
        tagNo: 'large'
      }
    ];

    this.autocompeteitems = [
      { title: 'SMALL SINGLE AUTOCOMPETE', disable: false, height: 'small' },
      { title: 'MEDIUM SINGLE AUTOCOMPETE', disable: false, height: 'medium' },
      { title: 'LARGE SINGLE AUTOCOMPETE', disable: false, height: 'large' },
      {
        title: 'LARGE SINGLE AUTOCOMPETE (DISABLED)',
        disable: true,
        height: 'large'
      }
    ];

    this.listitems = [

      {
        value: 'TDA, Europur method (extraction conditions:1 % aq. acetic acid, room temperature, 40 minutes)',
        text: 'TDA, Europur method (extraction conditions:1 % aq. acetic acid, room temperature, 40 minutes)'
      },
      {
        value: 'b',
        text: 'India'
      },
      {
        value: 'c',
        text: 'USA'
      },
      {
        value: 'd',
        text: 'England'
      },
      {
        value: 'e',
        text: 'Switzerland'
      },
      {
        value: 'f',
        text: 'Iran'
      },
      {
        value: 'g',
        text: 'Australia'
      },
      {
        value: 'h',
        text: 'Africa'
      }
    ];

    this.flowListItems = [

      {
        value: 'a',
        text: 'Label',
        disabled: false,
        checked: false
      },
      {
        value: 'b',
        text: 'Label',
        disabled: true,
        checked: true
      },
      {
        value: 'c',
        text: 'Label',
        disabled: true,
        checked: false
      },
      {
        value: 'd',
        text: 'Label',
        disabled: false,
        checked: true
      }
    ];
    this.flowRadioListItems = [

      {
        value: 'a',
        text: 'Option',
        disabled: false,
        checked: false
      },
      {
        value: 'd',
        text: 'Option',
        disabled: false,
        checked: true
      }
    ];
    this.flowRadioDisabledItems = [

      {
        value: 'a',
        text: 'Option',
        disabled: true,
        checked: false
      },
      {
        value: 'd',
        text: 'Option',
        disabled: true,
        checked: true
      }
    ];
  }

  public itemSupplierFilter1() {
    return ("startswith(ShipCountry,'#searchText')");
  }
  handleFilter(value) {
    this.data = this.autolistItems.filter((s) => s.toLowerCase().startsWith((value.toLowerCase())));

  }
  onFocus(event) {
    console.log('focus');
  }
  onTextChange(event) {
    console.log('textcahnge', event);
    this.ODataSearchService.getGridData().subscribe(res =>{
      this.autoCompleteData = res.data;
      console.log('this.autoCompleteData',this.autoCompleteData);
    })
  }

  onBlur(event) {
    console.log('focus');
  }
  onChange(event) {
    // this.selectedItem=event;
    // console.log(event, this.appForm.value);
    // console.log(this.selectedItemmultiple2);
    // console.log('automultvalue is' + this.selectedItemmultiple[0]);
  }
}