import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sdf-horizontal-menu',
  templateUrl: './sdf-horizontal-menu.component.html',
  styleUrls: ['./sdf-horizontal-menu.component.css']
})
export class SdfHorizontalMenuComponent implements OnInit {

  constructor() { }

  // menu = [
  //   { 
  //     text: "Home"
  //   },
  //   { 
  //     text: 'Menu 1'
  //   },
  //   { 
  //     text: 'Menu 2'
  //   },
  //   { 
  //     text: 'Menu 3'
  //   },
  // ]

  menu = [

    {

      text: "Menu 1",
      ref_link:'sdf-horisontal-menu'


    },

    {

      text: "Menu 2",

    },

  ];

  footer = {
    initials: { value: 'UN', visible: true},
    searchbar: true,
    items: [
      { icon: 'fas fa-sign-out-alt', method: 'Signout' },
      { icon: 'fas fa-cog', method: 'Settings' },
      { icon: 'fas fa-question', method: 'Help'},
      { icon: 'fas fa-bell', method: 'Alerts' }
    ]
  }
  title = {
    name: "App-Name",
    visible: true,
    path: '',
    icon_image:'../../../assets/home.svg',
    font_awesome:false
  };

  ngOnInit(): void {
  }

  exposeEvent(event){
   
    switch(event){

      case 'Help':
        location.href="mailto:jira.AESD.se@inter.ikea.com";
        break;

      case 'Alerts':
       //this.clickWidget();
        break;

    }
  }
  search(text) {
    alert('Searched '+text);
  }
}
