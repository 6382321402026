export const menu = [
    {
      title: "UI Components",
      path: "",
      route: "",
      disabled: false,
      children: [
        {
          title: "About",
          path: "",
          route: "ui-home",
          disabled: false,
        },
        {
          title: "Foundation",
          path: "",
          route: "",
          disabled: false,
          children: [
            {
              title: 'Colour',
              path: '',
              route: 'sdf-colour',
              disabled: false
            },
            {
              title: 'Typography',
              path: '',
              route: 'sdf-typography',
              disabled: false
            },
            {
              title: 'Iconography',
              path: '',
              route: 'sdf-iconography',
              disabled: false
            }
          ]
        },
        {
          title: "Action",
          path: "",
          route: "",
          disabled: false,
          children: [
            {
              title: 'Button',
              path: '',
              route: 'sdf-button',
              disabled: false
            },
            {
              title: 'Pills',
              path: '',
              route: 'sdf-pills',
              disabled: false
            }
          ]
        },
        {
          title: "Content",
          path: "",
          route: "",
          disabled: false,
          children: [
            {
              title: 'Accordion',
              path: '',
              route: 'sdf-accordion',
              disabled: false
            },
            {
              title: 'Tabs',
              path: '',
              route: 'sdf-tabs',
              disabled: false
            }
          ]
        },
        {
          title: "Input & controls",
          path: "",
          route: "",
          disabled: false,
          children: [
            {
              title: 'Radio button',
              path: '',
              route: 'sdf-radio',
              disabled: false
            },
            {
              title: 'Checkbox',
              path: '',
              route: 'sdf-checkbox',
              disabled: false
            },
            {
              title: 'Switch',
              path: '',
              route: 'sdf-switch',
              disabled: false
            },
            {
              title: 'Toggle',
              path: '',
              route: 'sdf-toggle',
              disabled: false
            },
            {
              title: 'Date picker',
              path: '',
              route: 'sdf-datepicker',
              disabled: false
            },
            {
              title: 'Input field',
              path: '',
              route: 'sdf-textbox',
              disabled: false
            },
            {
              title: 'Search field',
              path: '',
              route: 'sdf-search',
              disabled: false
            },
            {
              title: 'File upload',
              path: '',
              route: 'sdf-file-upload',
              disabled: false
            },
            {
              title: 'Autocomplete',
              path: '',
              route: 'sdf-autocomplete',
              disabled: false
            },
            {
                title: 'Dropdown',
                path: '',
                route: 'sdf-dropdown',
                disabled: false
            }
          ]
        },
        {
          title: "Navigation",
          path: "",
          route: "sdf-menu",
          disabled: false
        },
        {
          title: "Table",
          path: "",
          route: "sdf-table",
          disabled: false
        },
        {
          title: "Messaging",
          path: "",
          route: "",
          disabled: false,
          children: [
            {
              title: 'Banner',
              path: '',
              route: 'sdf-banner',
              disabled: false
            },
            {
              title: 'Inline messaging',
              path: '',
              route: 'sdf-inline-message',
              disabled: false
            },
            // {
            //   title: 'Inline alert',
            //   path: '',
            //   route: 'sdf-inline-alert',
            //   disabled: false
            // },
            // {
            //   title: 'Alert modal',
            //   path: '',
            //   route: 'sdf-alert-modal',
            //   disabled: false
            // },
            {
              title: 'Toast',
              path: '',
              route: 'sdf-toast',
              disabled: false
            },
            {
              title: 'Tooltip',
              path: '',
              route: 'sdf-tooltip',
              disabled: false
            },
            // {
            //   title: 'Information modal',
            //   path: '',
            //   route: 'sdf-information-modal',
            //   disabled: false
            // },
          ]
        },
        {
          title: "Notification system",
          path: "",
          route: "sdf-notification-system",
          disabled: false
        },
        {
          title: "SKAPA Web Components",
          path: "",
          route: "skapa-web-components",
          disabled: false
        },
        
        // {
        //   title: 'Select',
        //   path: '',
        //   route: 'sdf-select',
        //   disabled: false
        // },
        // {
        //   title: 'All',
        //   path: '',
        //   route: 'sdf-controls',
        //   disabled: false
        // }
      ],
    },
    // {
    //   title: "UI Components(old)",
    //   path: "",
    //   route: "",
    //   disabled: false,
    //   children: [
    //     {
    //     title: "Action",
    //       path: "",
    //       route: "",
    //       disabled: false,
    //       children: [
    //         {
    //           title: 'Button',
    //           path: '',
    //           route: 'sdf-new-button',
    //           disabled: false
    //         },
            
    //         {
    //           title: 'Icon Button',
    //           path: '',
    //           route: 'sdf-icon-button',
    //           disabled: false
    //         },
    //         {
    //           title: 'Pill',
    //           path: '',
    //           route: 'sdf-new-pills',
    //           disabled: false
    //         },
            
    //       ]
    //     },
    //     {
    //       title: "Content",
    //       path: "",
    //       route: "",
    //       disabled: false,
    //       children: [
    //         {
    //           title: 'Accordion',
    //           path: '',
    //           route: 'sdf-accordion',
    //           disabled: false
    //         },
    //         {
    //           title: 'Card',
    //           path: '',
    //           route: 'sdf-new',
    //           disabled: false
    //         },
    //         {
    //           title: 'List',
    //           path: '',
    //           route: 'sdf-new',
    //           disabled: false
    //         },
    //         {
    //           title: 'Tabs',
    //           path: '',
    //           route: 'sdf-tabs-old',
    //           disabled: false
    //         }
    //         // {
    //         //   title: 'Fixed card height, single page(new)',
    //         //   path: '',
    //         //   route: 'sdf-new',
    //         //   disabled: false
    //         // },
    //         // {
    //         //   title: 'Masonry cards, single page(new)',
    //         //   path: '',
    //         //   route: 'sdf-new',
    //         //   disabled: false
    //         // },
    //         // {
    //         //   title: 'Edit single card(new)',
    //         //   path: '',
    //         //   route: 'sdf-new',
    //         //   disabled: false
    //         // },
    //         // {
    //         //   title: 'Fixed card height, many pages(new)',
    //         //   path: '',
    //         //   route: 'sdf-new',
    //         //   disabled: false
    //         // },
    //         // {
    //         //   title: 'Masonry cards, many pages(new)',
    //         //   path: '',
    //         //   route: 'sdf-new',
    //         //   disabled: false
    //         // },
    //         // {
    //         //   title: 'Example of a card page(new)',
    //         //   path: '',
    //         //   route: 'sdf-new',
    //         //   disabled: false
    //         // },
           
            
    //       ]
    //     },
    //     {
    //       title: "Input & controls",
    //       path: "",
    //       route: "",
    //       disabled: false,
    //       children: [
    //         {
    //           title: 'Radio button',
    //           path: '',
    //           route: 'sdf-radio-old',
    //           disabled: false
    //         },
    //         {
    //           title: 'Checkbox',
    //           path: '',
    //           route: 'sdf-checkbox-old',
    //           disabled: false
    //         },
    //         {
    //           title: 'Switch',
    //           path: '',
    //           route: 'sdf-switch-old',
    //           disabled: false
    //         },
    //         {
    //           title: 'Toggle',
    //           path: '',
    //           route: 'sdf-new-toggle',
    //           disabled: false
    //         },
    //         {
    //           title: 'Slider',
    //           path: '',
    //           route: 'sdf-slider',
    //           disabled: false
    //         },
    //         {
    //           title: 'Date picker',
    //           path: '',
    //           route: 'sdf-datepicker-old',
    //           disabled: false
    //         },
    //         // {
    //         //     title:'Select',
    //         //     path:'',
    //         //     route:'sdf-select',
    //         //     disabled:false
    //         // },
           
    //         {
    //           title: 'Textbox',
    //           path: '',
    //           route: 'sdf-textbox-old',
    //           disabled: false
    //         },
    //         {
    //           title: 'Text area',
    //           path: '',
    //           route: 'sdf-textarea',
    //           disabled: false
    //         },
    //         {
    //           title: 'Search',
    //           path: '',
    //           route: 'sdf-search-field',
    //           disabled: false
    //         },
    //         {
    //           title: 'File Upload',
    //           path: '',
    //           route: 'sdf-file-upload',
    //           disabled: false
    //         },
    //         {
    //           title: "SDF Select",
    //           path: "",
    //           route: "sdf-select",
    //           disabled: false
    //         },
    //         {
    //           title: 'Dropdowns, single',
    //           path: '',
    //           route: 'sdf-single-dropdown',
    //           disabled: false
    //         },
    //         {
    //           title: 'Dropdowns, multi',
    //           path: '',
    //           route: 'sdf-multi-dropdown',
    //           disabled: false
    //         },
    //         {
    //           title: 'Autocomplete, single',
    //           path: '',
    //           route: 'sdf-single-autocomplete',
    //           disabled: false
    //         },
    //         {
    //           title: 'Autocomplete, multi',
    //           path: '',
    //           route: 'sdf-multi-autocomplete',
    //           disabled: false
    //         },
    //         {
    //           title: 'Single H. Dropdown',
    //           path: '',
    //           route: 'sdf-single-hierarchy-dropdown',
    //           disabled: false
    //         },
    //         {
    //           title: 'Single H. Autocomplet',
    //           path: '',
    //           route: 'sdf-single-hierarchy-autocomplete',
    //           disabled: false
    //         },
    //         {
    //           title: 'Multi H. Dropdown',
    //           path: '',
    //           route: 'sdf-multi-hierarchy-dropdown',
    //           disabled: false
    //         },
    //          {
    //            title: 'Multi H. Autocomplete',
    //            path: '',
    //            route: 'sdf-multi-hierarchy-autocomplete',
    //            disabled: false
    //          },
    //       ]
    //     },
    //     {
    //       title: "Messaging",
    //       path: "",
    //       route: "",
    //       disabled: false,
    //       children: [
    //         {
    //           title: 'Banner',
    //           path: '',
    //           route: 'sdf-banner',
    //           disabled: false
    //         },
    //         {
    //           title: 'Inline messaging',
    //           path: '',
    //           route: 'sdf-inline-message',
    //           disabled: false
    //         },
    //         {
    //           title: 'Inline alert',
    //           path: '',
    //           route: 'sdf-inline-alert',
    //           disabled: false
    //         },
    //         {
    //           title: 'Alert modal',
    //           path: '',
    //           route: 'sdf-alert-modal',
    //           disabled: false
    //         },
    //         {
    //           title: 'Toast',
    //           path: '',
    //           route: 'sdf-toast',
    //           disabled: false
    //         },
    //         {
    //           title: 'Tooltip',
    //           path: '',
    //           route: 'sdf-tooltip',
    //           disabled: false
    //         },
    //         {
    //           title: 'Information modal',
    //           path: '',
    //           route: 'sdf-information-modal',
    //           disabled: false
    //         },
    //       ]
    //     },
    //     {
    //       title: "Navigation",
    //       path: "",
    //       route: "",
    //       disabled: false,
    //       children: [
    //         {
    //           title: 'Horisontal Menu',
    //           path: '',
    //           route: 'sdf-horizontal-menu',
    //           disabled: false
    //         },
    //         {
    //           title: 'Vertical Menu',
    //           path: '',
    //           route: 'sdf-vertical-menu-default',
    //           disabled: false
    //         },
    //         // {
    //         //   title: 'Vertical menu collapsed',
    //         //   path: '',
    //         //   route: 'sdf-vertical-menu-collapsed',
    //         //   disabled: false
    //         // },
    //         {
    //           title: 'Hyperlinks',
    //           path: '',
    //           route: 'sdf-hyperlinks',
    //           disabled: false
    //         },
    //         {
    //           title: 'Breadcrumb',
    //           path: '',
    //           route: 'sdf-breadcrumbs',
    //           disabled: false
    //         }
    //       ]
    //     },
    //     {
    //       title: "Table",
    //       path: "",
    //       route: "",
    //       disabled: false,
    //       children: [
    //         {
    //           title: 'Table',
    //           path: '',
    //           route: 'sdf-grid',
    //           disabled: false
    //         },
    //         // {
    //         //   title: 'Styles(new)',
    //         //   path: '',
    //         //   route: 'sdf-styles',
    //         //   disabled: false
    //         // },
    //         // {
    //         //   title: 'Sizes(new)',
    //         //   path: '',
    //         //   route: 'sdf-sizes',
    //         //   disabled: false
    //         // },
    //         // {
    //         //   title: 'Large grid cell states',
    //         //   path: '',
    //         //   route: 'sdf-large-grid-cell-states',
    //         //   disabled: false
    //         // },
    //         // {
    //         //   title: 'Medium grid cell states',
    //         //   path: '',
    //         //   route: 'sdf-medium grid-cell-states',
    //         //   disabled: false
    //         // },
    //         // {
    //         //   title: 'Small grid cell states',
    //         //   path: '',
    //         //   route: 'sdf-small-grid-cell-states',
    //         //   disabled: false
    //         // },
    //         // {
    //         //   title: 'Sorting',
    //         //   path: '',
    //         //   route: 'sdf-table-sorting',
    //         //   disabled: false
    //         // },
    //         // {
    //         //   title: 'Common filter(new)',
    //         //   path: '',
    //         //   route: 'sdf-table-common-filter',
    //         //   disabled: false
    //         // },
    //         // {
    //         //   title: 'Filter row',
    //         //   path: '',
    //         //   route: 'sdf-table-filter-row',
    //         //   disabled: false
    //         // },
    //         // {
    //         //   title: 'Horizontal filter(new)',
    //         //   path: '',
    //         //   route: 'sdf-horizontal-filter',
    //         //   disabled: false
    //         // },
    //         // {
    //         //   title: 'Vertical filter(new)',
    //         //   path: '',
    //         //   route: 'sdf-vertical-filter',
    //         //   disabled: false
    //         // },
    //         // {
    //         //   title: 'Table headers(new)',
    //         //   path: '',
    //         //   route: 'sdf-table-headers',
    //         //   disabled: false
    //         // },
    //         // {
    //         //   title: 'Vertical scroll',
    //         //   path: '',
    //         //   route: 'sdf-vertical-scroll',
    //         //   disabled: false
    //         // },
    //         // {
    //         //   title: 'Horizontal scroll(new)',
    //         //   path: '',
    //         //   route: 'sdf-horizontal scroll',
    //         //   disabled: false
    //         // },
    //         // {
    //         //   title: 'Row hover',
    //         //   path: '',
    //         //   route: 'sdf-row-hover',
    //         //   disabled: false
    //         // },
    //         // {
    //         //   title: 'Tabs(new)',
    //         //   path: '',
    //         //   route: 'sdf-tab',
    //         //   disabled: false
    //         // },
    //         // {
    //         //   title: 'Single select',
    //         //   path: '',
    //         //   route: 'sdf-table-single-select',
    //         //   disabled: false
    //         // },
    //         // {
    //         //   title: 'Multiselect',
    //         //   path: '',
    //         //   route: 'sdf-table-multiselect',
    //         //   disabled: false
    //         // },
    //         // {
    //         //   title: 'Column width & wrapped text',
    //         //   path: '',
    //         //   route: 'sdf-column width-wrapped-text',
    //         //   disabled: false
    //         // },
    //         // {
    //         //   title: 'Expand/collapse long texts',
    //         //   path: '',
    //         //   route: 'sdf-expand-collapse-long-texts',
    //         //   disabled: false
    //         // },
    //         // {
    //         //   title: 'Edit single row',
    //         //   path: '',
    //         //   route: 'sdf-edit-single-row',
    //         //   disabled: false
    //         // },
    //         // {
    //         //   title: 'Edit multiple rows',
    //         //   path: '',
    //         //   route: 'sdf-edit-multiple-rows',
    //         //   disabled: false
    //         // }
    //       ]
    //     },
    //     {
    //       title: "Miscellaneous",
    //       path: "",
    //       route: "",
    //       disabled: false,
    //       children: [
    //         {
    //           title: 'Footer',
    //           path: '',
    //           route: 'sdf-page-footer',
    //           disabled: false
    //         }
    //       ]
    //     },
    //     {
    //       title: "Complex functionalities",
    //       path: "",
    //       route: "",
    //       disabled: false,
    //       children: [
    //         {
    //           title: 'Notification system',
    //           path: '',
    //           route: 'sdf-colours',
    //           disabled: false
    //         }
    //         // {
    //         //   title: 'Link',
    //         //   path: '',
    //         //   route: 'sdf-typrography',
    //         //   disabled: false
    //         // } 
    //       ]
    //     },
        

    //   ]
    // },
    {
      title: "Backend",
      path: "",
      route: "sdf-backend",
      disabled: false
    },
    {
      title: "Cloud & Infra",
      path: "",
      route: "sdf-cloud",
      disabled: false
    },
    
    {
      title: "Contact",
      path: "",
      route: "sdf-contact",
      disabled: false
    },

    // {
    //   displayName: "Components List",
    //   path:"",
    //   route:"",
    //   children: [
    //     {
    //       displayName: 'Textbox',
    //       path: '',
    //       route: 'sdf-textbox',

    //     },
    //     {
    //       displayName: 'Button',
    //       path: '',
    //       route: 'sdf-button',
    //     },
    //     {
    //       displayName: 'Select',
    //       path: '',
    //       route: 'sdf-select',
    //     }
    //  ]
    // },
    //{
    // title: "Logout",
   //   path: "",
    //  route: "login",
    //  disabled: false
    //},
    //{
    //  title: "Disabled",
    //  path: "",
   //   route: "login",
    //  disabled: true
    //},
   // {
    //  title: "Disabled 2",
    //  path: "",
    //  route: "login",
    //  disabled: true,
    //  children: [
    //    {
   //       displayName: 'Textbox',
    //      path: '',
    //  //    route: 'sdf-textbox',

    //    }]
    //}
  ];