import { Injectable } from '@angular/core';
import { HttpClient,HttpParams,HttpHeaders  } from  '@angular/common/http';
import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PageEditingService {


  

  constructor(private http: HttpClient) { }


  // all links ar here 
  get_link:string= environment.component_link_get;


  // demo component link data
  c_link_data:any = [
   
  ]
  /*
  c_section_data:any= [
    {
      "id": 3,
      "componentName": "sdf-autocomplete",
      "sectionTitle": "                  ",
      "sectionContent": "<div style='overflow-y:hidden;display:block'><div style='width:58%;float:left'><div style='height:24px;width:100%;background:#eeeeee'></div><div style='height:24px;width:100%;background:#eeeeee;margin-top:6px'></div><div style='height:24px;width:100%;background:#eeeeee;margin-top:6px'></div><div style='height:24px;width:60%;background:#eeeeee;margin-top:6px'></div></div><div style='width:40%;float:right;background:#cccccc;height:145px'></div></div",
      "sectionOrder": 1  },
    {
      "id": 3,
      "componentName": "sdf-autocomplete",
      "sectionTitle": "                  ",
      "sectionContent": "<div style='overflow-y:hidden;display:block'><div style='width:58%;float:left'><div style='height:24px;width:100%;background:#eeeeee'></div><div style='height:24px;width:100%;background:#eeeeee;margin-top:6px'></div><div style='height:24px;width:100%;background:#eeeeee;margin-top:6px'></div><div style='height:24px;width:60%;background:#eeeeee;margin-top:6px'></div></div><div style='width:40%;float:right;background:#cccccc;height:145px'></div></div",
      "sectionOrder": 1  }
  ]*/

  c_section_data:any= [];


  // get component links details
  get_component_links(cm:string){
    let url = this.get_link+'?componentName='+cm;
    return this.http.get(url);
  }

  // add edit component links 
  add_edit_component_link(d:any,ty:string,url:string){

    const formData = new FormData();

    formData.append(ty,JSON.stringify(d));
    
    return  this.http.post(url, formData);

  }





  // add new component link  --- edit
  add_new_component_link(d:any){
    console.log(d);
    this.add_edit_component_link(d,'componentLinkForm',environment.component_link_update).subscribe(
      (response)=>{console.log(response)},
      (error)=>{console.log(error)}
    )
  }

  // dele component link
  del_component_link(d:any){
    console.log(d);
    let url = environment.component_link_del+'?link_id='+d.id;
    this.http.delete(url).subscribe(
      (response)=>{
        //console.log(response)

      },
      (error)=>{console.log(error)}
    );
  }




  // get component section details
  get_component_section(cm:string){
    let url = environment.compenent_section_get+'?componentName='+cm;
    return this.http.get(url);
  }

  // dele component link
  del_component_section(d:any){
    console.log(d);
    let url = environment.component_section_del+'?section_id='+d.id;
    this.http.delete(url).subscribe(
      (response)=>{
        //console.log(response)
        window.location.reload();
      },
      (error)=>{console.log(error)}
    );
  }




  // get sas token
  get_sas_token(){
    let url = environment.sas_token;
    return this.http.get(url,{responseType: 'text'});
  }


  fetch_private_img(url:string,token:string):any{
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("x-ms-version", "2020-10-02");

    var requestOptions:any = {
      method: 'GET',
      mode:'cors',
      headers: myHeaders,
      responseType: 'blob',
      redirect: 'follow'
    };
    return fetch(url, requestOptions)
  }

  bearer_token():any{
    return this.http.get(`${environment.blob_token}`,{responseType:'text'});
  }

}


