import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { SdfTextboxSampleComponent } from '../sdf-textbox-sample/sdf-textbox-sample.component';
import { HomeComponent } from '../modules';
import { NONE_TYPE } from '@angular/compiler';

import { AuthenticationService } from 'src/core/services/authentication.service';
import { HomeService } from '../modules/home/home.service';
import { DomSanitizer } from '@angular/platform-browser';
import { SdfAlertsService } from '@sdf/sdf-ui';
//import { ConsoleReporter } from 'jasmine';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-sdf-horizontal-sample',
  templateUrl: './sdf-horizontal-sample.component.html',
  styleUrls: ['./sdf-horizontal-sample.component.scss']
})
export class SdfHorizontalSampleComponent implements OnInit {
  
  public userData;
  public showNotification = false;
  newNotification: boolean = false;

  left_to:string='270px';

  full_page_loading:boolean=false;

  /*menu = [
    { 
      text: "Home",
      path: "home"
    },
    { 
      text: 'Action',
      children :[
        {
          text: 'Buttons',
          path: 'sdf-button'
        },
        {
          text: 'Pills',
          path: 'sdf-pills'
        },
        {
          text: 'Switches',
          path: 'sdf-switch'
        },
        {
          text: 'Toggle',
          path: 'sdf-toggle'
        }
      ]
    },
    { 
      text: 'Forms',
      children:[
        {
          text: 'Radio-Button',
          path:'sdf-radio'
        },
        {
          text: 'Check Box',
          path: 'sdf-checkbox'
        },
        {
          text: 'Dropdown',
          path: 'sdf-dropdown'
        },
        {
          text: 'Autocomplete Single',
          path: 'sdf-autocomplete-single'
        },
        {
          text: 'Autocomplete Multiple',
          path: 'sdf-autocomplete-multiple'
        },
        {
          text: 'TextBox',
          path:'sdf-textbox'
        },
        {
          text: 'Text Area',
          path: 'sdf-textarea'
        },
        {
          text: 'DatePicker',
          path: 'sdf-datepicker'
        }
      ]
    },
    { 
      text: 'Content',
      children:[
        {
          text: 'Accordion',
          path: 'sdf-accordion'
        },
        {
          text: 'Grid',
          path: 'sdf-grid'
        },
        {
          text: 'Tabs',
          path: 'sdf-tabs'
        }
      ]
    },
    { text: 'Message',
      children:[
        {
          text: 'Banners',
          path: 'sdf-banner'
        },
        {
          text: 'Inline Message',
          path: 'sdf-inline-message'
        },
        {
          text: 'Tooltip',
          path: 'sdf-tooltip'
        }
      ]
    },
    { 
      text: 'New Controls',
      children:[
        {
          text: 'Single Select Autocomplete',
          path: 'sdf-single-autocomplete'
        },
        {
          text: 'Multi Select Autocomplete',
          path: 'sdf-multi-autocomplete'
        },
        {
          text: 'Single Select DropDown',
          path:'sdf-single-dropdown'
        },
        {
          text:'Multi Select DropDown',
          path:'sdf-multi-dropdown'
        }
      ]
    },
  ]*/
  menu=[];

  footer = {
      initials: { value: 'JU', visible: true},
      searchbar: true,
      items: [
        { icon: 'fas fa-sign-out-alt', method: 'Signout' },
        { icon: 'fas fa-cog', method: 'Settings' },
        { icon: 'fas fa-question', method: 'Help'},
        { icon: 'fas fa-bell', method: 'Alerts' }
      ]
    }
  

  title = {
    name: "SDF-UI",
    visible: true,
    path: ''
  };

  constructor(private cdref: ChangeDetectorRef,private authenticationService: AuthenticationService, private sdfAlertService: SdfAlertsService, private homeService: HomeService,private _sanitizer: DomSanitizer) {

  }
  
  ngOnInit(){
    
    //console.log('testing', this.authenticationService.userData);
    this.userData = this.authenticationService.userData;
    let data = {
      'appCode': 'SDF',//'ToCompete',
      'userId': JSON.parse(localStorage.getItem('user'))
    }
    this.homeService.checkNotification(data).subscribe(res => {
      let flag: any = res;
      this.newNotification = flag;
    });

  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  exposeEvent(event){
   
    switch(event){

      case 'Help':
        location.href="mailto:jira.AESD.se@inter.ikea.com";
        break;

      case 'Alerts':
       //this.clickWidget();
        break;

    }


    //alert('Invoked '+event);
  }
  resizeIframe(obj) {
    obj.style.height = obj.contentWindow.document.documentElement.scrollHeight + 'px';
  }
  
  url:any;nonprodWid:boolean=false;prodWid:boolean=false;gb_type:string='widget';
  clickWidget(prod:boolean=false,type:string='widget') {
    // document.getElementById('navbar-widget-icon').click();
    this.gb_type = type;
    if(prod){
      this.url = environment.prod_widget;
      this.prodWid = this.load_once ? false :true;
      if(this.nonprodWid){
        this.nonprodWid = false;
        setTimeout(()=>{this.clickWidget(true,type)},500);
      }
    }else{
      this.url = environment.nonprod_widget;
      this.nonprodWid = this.load_once ? false : true;
      if(this.prodWid){
        this.prodWid = false;
        setTimeout(()=>{this.clickWidget(false,type)},500);
      }
    }
    this.load_once=false;
    if(!this.showNotification){
    
      this.userData = this.authenticationService.userData;
      let data = {
        'appCode': 'SDF',//'ToCompete',
        'userId': JSON.parse(localStorage.getItem('user'))
      }
      this.homeService.updateNotification(data,prod,type).subscribe(res => {
      });
      this.showNotification = true;
      this.newNotification = false;
  
    }else{
      this.showNotification = false;
      this.newNotification = true;
    }
  }

  closePopup() {
    this.showNotification = false;
    this.nonprodWid = false;
    this.prodWid = false;
    //this.showNotification = false;
  }

  load_once:boolean=false;
  iframeURLsafe(){
    if(this.load_once || this.url.length<10)return;
    let type="widget";this.load_once=true;
    let url = this.url+"/?appCode=SDF&userId=" + this.userData + "&type="+ this.gb_type;
    console.log('yes me',url)
    // setTimeout(()=>{//mySlides fade
    //   let el:HTMLCollectionOf<Element> = document.getElementsByClassName('mySlides');
    // },5000)
    return this._sanitizer.bypassSecurityTrustResourceUrl(url);
    
  }

  search(text) {
    alert('Searched '+text);
  }



  private loadone:boolean = false;
  isPopup:boolean=false;
  loadpopupiFrame(){
    if(this.loadone)return;this.loadone = true;
    let type="popup";
    //let url = "https://notificationwidget.azurewebsites.net/?appCode=ToCompete&appSection=*&userId=" + this.userData + "&type="+ type;
    let url = environment.nonprod_widget+"/?appCode=SDF&userId=" + JSON.parse(localStorage.getItem('user')) + "&type="+ type;
    
    return this._sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  closePopup1(){
    this.isPopup = false;
    this.closeNotificationPopup();
  }

  closeNotificationPopup(){
    let data = {
      'appCode':'SDF' ,//'ToCompete',
      'userId': JSON.parse(localStorage.getItem('user'))
    }
    this.homeService.closePopupNotification(data).subscribe(res => {
      //console.log(res)
    });
  }

}
