import { ChangeDetectorRef, Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sdf-toggle',
  templateUrl: './sdf-toggle.component.html',
  styleUrls: ['./sdf-toggle.component.css']
})
export class SdfToggleComponent implements OnInit {

  constructor(private cdref: ChangeDetectorRef) { }

  hide_opt:boolean = false;hide_code:boolean=true;
  toggle_choice:any={
    'width':{
      'Fluid':'fluid',
      'Auto':'auto'
    },
    'state':{
      'Normal':'normal',
      'Disable':'disable'
    },
    'options':{
      '2':'2',
      '3':'3',
      '4':'4',
      '5':'5'
    }
  }

  toggle_o_selected:any={
    'width':'auto',
    'state':'normal',
    'options':'3'
  }

  default_value:string='two';
  public label_array:any={
    '2':['one','two'],
    '3':['one','two','three'],
    '4':['one','two','three','four'],
    '5':['one','two','three','four','five']
  }

  ngOnInit(): void {
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  i_repeat:number=0;hide_component:boolean=true;
  toggle_selected(val,v){
    //console.log(this.label_array[this.toggle_o_selected['options']])
    if(v=='options'){
      let na = this.label_array[val];
      if(na.indexOf(this.default_value)<0){
        this.default_value = na[0];
      }
    }
    
    this.i_repeat++;
    if(this.i_repeat>2){
      this.toggle_o_selected[v]=this.toggle_choice[v][val];
      console.log(this.toggle_o_selected);
      this.hide_component=false;
      setTimeout(()=>{
        this.hide_component=true;
      },2)
    }
    
  }

  toggle_clicked(event){
    this.default_value=event;
    //console.log(event);
  }

  copy_code(eli:any){
    eli = 'rtyur';//this.toggle_o_selected['thumbnail']=='none'?'rtyu':'rtyur';
    let el = document.getElementById(eli) as HTMLElement;
    let da = el.innerHTML.replace(/&lt;/g,'<');
    da = da.replace(/&gt;/g,'>')
    navigator.clipboard.writeText(da);
    console.log(da)
  }

}
