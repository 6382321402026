import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sdf-new-toggle-sample',
  templateUrl: './sdf-new-toggle-sample.component.html',
  styleUrls: ['./sdf-new-toggle-sample.component.css']
})
export class SdfNewToggleSampleComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  public label_array=['one','two','three']


  toggle_selected(st:string){
    console.log(st)
  }
  df:string='three';
  check_with(){
    this.df='';
  }
}
