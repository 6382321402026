import { Component, ElementRef, OnInit, ViewChild, ChangeDetectorRef, HostListener } from '@angular/core';
import { all_data } from '../sdf-new-autocomplete-sample/sdf-autocomplete-data';
import { DomSanitizer,SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-sdf-new-dropdown',
  templateUrl: './sdf-new-dropdown.component.html',
  styleUrls: ['./sdf-new-dropdown.component.css']
})
export class SdfNewDropdownComponent implements OnInit {

   

  constructor(private sanitizer: DomSanitizer,private cdref: ChangeDetectorRef) { }

  // toggle options
  selection_type:any=['Single','Multiple'];
  component_height:any=['Flexible','Fixed'];
  field_height:any=['32px','40px','48px'];
  max_list_height:any=['12px','18px','24px'];
  levels:any=['Flat','Hierarchical','Group'];
  label:any=['On frame','Outside'];
  search_filter:any=['Starts with','Ends with','Includes'];
  thumbnail:any=['None','Circular','Square'];

  cmp_height:string='Flexible';

  toggle_choice:any={
    'selection_type':{
      'Single':'single',
      'Multiple':'multiple'
    },
    'component_height':{
      'Fixed':'fixed',
      'Flexible':'flexible'
    },
    'field_height':{
      '32px':'small',
      '40px':'medium',
      '48px':'large'
    },
    'max_list_height':{
      '12px':'small',
      '18px':'medium',
      '24px':'large'
    },
    'levels':{
      'Flat':'flat',
      'Hierarchical':'hierarchy',
      'Group':'group-list'
    },
    'label':{
      'On frame':'inside',
      'Outside':'outside'
    },
    'search_filter':{
      'Starts with':'startswith',
      'Includes':'contains',
      'Ends with':'endswith'
    },
    'thumbnail':{
      'None':'none',
      'Square':'square',
      'Circular':'circle'
    }
  }

  toggle_o_selected:any={
    'selection_type':'single',
    'component_height':'flexible',
    'field_height':'medium',
    'max_list_height':'medium',
    'levels':'flat',
    'label':'inside',
    'search_filter':'startswith',
    'thumbnail':'none'
  }

/*
  @ViewChild('preview',{static:false}) preview:ElementRef;
  @ViewChild('guidelinesel',{static:false}) guidelinesel:ElementRef;

  // guidelines var 
  up_guide:any='';
  guidelines:any='<h2 class="u-mb1" id="getting-and-setting-html-content" style="margin-top: -70px !important; margin-right: 0px; margin-bottom: 10px !important; margin-left: 0px; font-style: normal; font-variant: normal; font-weight: 300; font-stretch: normal; font-size: 50px; line-height: 1.2em !important; font-family: FaktSlabPro; letter-spacing: -0.03em; color: rgb(0, 0, 0); overflow-wrap: break-word; word-break: break-word; box-sizing: border-box; padding-top: 70px !important;">Getting and Setting HTML Content</h2><p style="margin: 0px; padding: 0px; box-sizing: border-box;">The Kendo UI for Angular Editor allows developers to get and set the content of the Editor programmatically. Thanks to this, loading and saving content to a database, or even manipulating the content before or after users have edited the Angular Editor’s content, can be implemented with ease.</p><p><img alt="Kendo UI for Angular Editor - Getting and Setting HTML Content" src="https://d585tldpucybw.cloudfront.net/sfimages/default-source/component-pages/angular/angular-editor-getting-and-setting-html-content.jpg?sfvrsn=a7e62dda_0"></p><ul><li><p>PlaceholdersUse placeholders to define initial text to be displayed in the input area of an Angular editor. A typical example is displaying “Enter text here” to direct a user to add their information.</p></li><li><p>Resizing</p><p style="margin: 0px 0px 20px; padding: 0px; box-sizing: border-box;">By default, the Angular Rich Text editor is not resizable, but there are cases where end-users would like to adjust their work area to be bigger or smaller. For that case, all you need to do is set the resizable option to true. You can restrict the dimensions to keep the Editor in a particular space or set no limits at all.</p></li></ul>';



  add_g_opt:boolean=false;
  save_disable:boolean=true;
  cancel_disable:boolean=true;
  preview_disable:boolean=true;
  option = [
    'format','fontSize','fontFamily','fontColor','backColor','bold','italic','underline',
    'strikethrough','subscript','supercript','alignment','unorderedList','orderedList','indent',
    'outdent', 'createLink', 'unLink', 'insertFile', 'insertImage', 'viewSource'
  ];
*/
  hide_code:boolean=true;
  all_d:any=all_data;drop_down_width:any;
  default_selected:any=['Andorra','Algeria'];

  //components link
  c_link_act:boolean=false;

  load_first:boolean = true;
  ngOnInit() {
   //this.up_guide = this.up_guide==''?this.guidelines:this.up_guide;
   setTimeout(()=>{
     this.load_first = false;
   },1500)
  //  setTimeout(()=>{
  //   this.ds = [
  //     {'valueee':'India'},
  //     {valueee:"Yemen"},
  //     {valueee:"Zambia"},
  //   ];;
  // },9000)
  }
  ds:any = [
    {'valueee':'India'}
  ];

  ngAfterContentChecked() {
    this.cdref.detectChanges();
    
    //this.hg_ht='32px';
  }


  single_default_value:string='Algeria';
  dropdown_width:string;
  valueChanged(object){
    // object is selected object -- object.value, object.ref_value, object.extra_val
    this.single_default_value=object;
    console.log(typeof(object));
  }

  valueCleared(){
    console.log('value cleared');
  }

  dp_change(val){
    //console.log(val)
    this.dropdown_width=val.value+'px';
    console.log(this.dropdown_width)
  }
  
  valueSelected(object){
    console.log(object);
  }

  hide_components:boolean=true;
  hg_ht:string='32px';thumbnail_hide:boolean=true;
  toggle_selected(st:string,ty:string=''){
    console.log(ty,st)
    if(st=='Flat' && this.toggle_o_selected['thumbnail']!='none'){
      this.thumbnail_hide=false;
      setTimeout(()=>{
        this.toggle_o_selected['thumbnail']='none';
        this.thumbnail_hide=true;
      },5)
    }
    this.toggle_o_selected[ty]=this.toggle_choice[ty][st];
    if(this.toggle_o_selected['selection_type']=='single' && this.toggle_o_selected['component_height']=='fixed'){
      //this.cmp_height = 'Flexible';
      setTimeout(()=>{
        this.toggle_o_selected['component_height']='flexible';
      },2);
    }
    //this.single_default_value='';
    this.hide_components=false;
    setTimeout(()=>{
      this.hide_components=true;
    },1)

    //this.update_components();
    let kc = ['32px','40px','48px'];
    //console.log(this.toggle_o_selected['search_filter'])
    if(kc.indexOf(st)>-1)
      this.hg_ht=st;
  }

  hide_opt:boolean=false;
  hide_option(val:string){
    if(val=='hide'){
      this.hide_opt=true;
    }else{
      this.hide_opt=false;
    }
  }

  flex_width:boolean=false;
  comp_width(v,val){
    if(v=='Fixed width'){
      this.flex_width=true;
    }else{
      this.flex_width=false;
    }
  }

  correct_width(){
    let v = parseInt(this.drop_down_width);
    if(v>800){
      this.drop_down_width=800;
    }else if(v<0){
      this.drop_down_width=100;
    }
  }

  dis_error:boolean=false;error_msg:string=' ';
  val_change(d:any){
    let n  = parseInt(d.value);
    
    if(n > 800){
      this.dis_error=true;
      this.error_msg='*max value is 800';
    }else if(n<0){
      this.dis_error=true;
      this.error_msg='*can\'t be negative or characters';
    }else{
      this.dis_error=false;
      this.error_msg=' ';
    }
   
  }

  copy_code(eli:any){
    eli = 'rtyur';//this.toggle_o_selected['thumbnail']=='none'?'rtyu':'rtyur';
    let el = document.getElementById(eli) as HTMLElement;
    let da = el.innerHTML.replace(/&lt;/g,'<');
    da = da.replace(/&gt;/g,'>')
    navigator.clipboard.writeText(da);
    console.log(da)
  }

}

