import { ChangeDetectorRef, Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sdf-file-upload-sample',
  templateUrl: './sdf-file-upload-sample.component.html',
  styleUrls: ['./sdf-file-upload-sample.component.scss']
})
export class SdfFileUploadSampleComponent implements OnInit {
  restrictions = {
    allowedExtensions: ['.pdf'],
    maxFileSize: 2097152,
    minFileSize: 10
  }
    public files: Array<any> = []
//     = [
//     { name: 'First.txt', size: 500 },
//     { name: 'Second.jpg', size: 100 }
// ];
  constructor(private cdref: ChangeDetectorRef) { }

  hide_opt:boolean = false;hide_code:boolean=true;
  toggle_choice:any={
    'type':{
      'File Select':'file_select',
      'Auto Upload':'auto_upload',
      'Manual Upload':'manual_upload'
    }
  }

  toggle_o_selected:any={
    'type':'file_select'
  }

  ngOnInit() {
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  i_repeat:number=0;hide_component:boolean=true;
  toggle_selected(val,v){
    this.i_repeat++;
    
   
    if(this.i_repeat>1){
      this.toggle_o_selected[v]=this.toggle_choice[v][val];
      this.hide_component=false;
      setTimeout(()=>{
        this.hide_component=true;
      },2)
    }
    
  }

  clicked(){
    console.log('button clicked')
  }

  copy_code(eli:any){
    eli = 'rtyur';//this.toggle_o_selected['thumbnail']=='none'?'rtyu':'rtyur';
    let el = document.getElementById(eli) as HTMLElement;
    let da = el.innerHTML.replace(/&lt;/g,'<');
    da = da.replace(/&gt;/g,'>')
    navigator.clipboard.writeText(da);
    //console.log(da)
  }

  outputEvent(data){
    console.log(data);
  }

  checkFile(data){
    console.log(data);
    this.files = data.target.files;
  }

  fileChange(data){
    let fileArray = []
    let uploadedfiles = data.target.files;
    for(let i= 0; i<uploadedfiles.length; i++){
      let file = {};
      let element = uploadedfiles[i]
      file['rawFile'] = element;
      file['name'] = element.name;
      file['size'] = element.size;
      file['type'] = element.type;
      fileArray.push(file)
    }
    
    this.files = [...fileArray];
  }


}
