import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-sdf-datepicker-sample',
  templateUrl: './sdf-datepicker-sample.component.html',
  styleUrls: ['./sdf-datepicker-sample.component.css']
})
export class SdfDatepickerSampleComponent implements OnInit {
  public value: Date = new Date();
  public value1: Date = new Date();
  public value2: Date = new Date();
  public value3: Date = new Date();
  constructor(private formBuilder: FormBuilder) {}
 
   ngOnInit() {}
 
   onChange(event) {
     console.log(event);
   }
 
   onKeypress(event) {
     console.log(event);
   }
 
   onFocus(event) {
     console.log(event);
   }
 
   onBlur(event) {
     console.log(event);
   }
 }
